import { Injectable } from '@angular/core';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { GSalesReportByDate } from 'src/app/viewmodels/g-settings/GSalesReportByDate';
import { Paginator } from 'src/app/viewmodels/paginator';
import { IHttpService } from '../../common/IHttpService';

@Injectable({
  providedIn: 'root'
})
export class SalesReportServiceService  {
  private _ep: EndpointConfig;
  constructor(private _client: HttpClient) {
    this._ep = new EndpointConfig('GSale');
  }

  GetSalesReport(obj : GSalesReportByDate) {
    debugger
    let pager = new Paginator(0, 20, "", 0);
    pager.FromDate = obj.StartDate;
    pager.ToDate = obj.EndDate;
    pager.AccId = Number(obj.AccId[0].Id);
    pager.Search = "";
    return this._client.post(this._ep.getEndpointMethod('GetG_SalesList'), pager);
  }

}
