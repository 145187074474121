import { WareHouseViewModel } from './../../../../viewmodels/ItemViewModel';
import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ItemStockReportViewModel } from "src/app/viewmodels/g-item/ItemStockReportViewModel";
import { StockReportTotalViewModel } from "src/app/viewmodels/g-item/StockReportTotalViewModel";
import { NgxSpinnerService } from "ngx-spinner";
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';

@Component({
  selector: "app-warehousestockreport",
  templateUrl: "./warehousestockreport.component.html",
  styleUrls: ["./warehousestockreport.component.scss"],
})
export class WarehousestockreportComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  public itemVm: ItemStockReportViewModel = new ItemStockReportViewModel();
  public stockTotal: StockReportTotalViewModel = new StockReportTotalViewModel();
  public wareHouseVm = new WareHouseViewModel();
  public vmListItems: any = [];
  p: number = 1;
  public component_Title = "WareHouse Stock Report";
  public wareHouses:Array<any>=[];
  public client: any;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.getWarehouse();
  }
  getWareHouseReport(id) {
    this.itemVm = new ItemStockReportViewModel();
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.itemService.GetWarehouseItemReport(id).subscribe(
      (res: any) => {
        this.vmListItems = res.StockReportList;
        this.stockTotal = res;
        console.log(res);
        console.log(this.itemVm);
        // this._jq.showSpinner();
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        // this._jq.showSpinner();
        this.spinner.hide();
      }
    );
  }
  getWarehouse(){
    this._uow.warehouseService.GetListByUserId().subscribe((res:any) =>{
      console.log('res',res);
      this.wareHouses = res;
    })
  }
  onSelectWareHouse(){
console.log(this.wareHouseVm.FK_WareHouse_Id);
this.getWareHouseReport(this.wareHouseVm.FK_WareHouse_Id.Id);
  }
}
