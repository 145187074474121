import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { PurchaseDetailViewModel } from 'src/app/viewmodels/g-purchase/PurchaseDetailViewModel';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { G_WareHouseViewModel } from 'src/app/viewmodels/g-settings/G_Warehouse_Viewmodel';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';
@Component({
  selector: 'app-purchase-form',
  templateUrl: './purchase-form.component.html',
  styleUrls: ['./purchase-form.component.scss']
})
export class PurchaseFormComponent implements OnInit {

  @Input() public vm: G_PurchaseMasterViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  modalRef: BsModalRef;
  public vmD: DefineAccountviewModel = new DefineAccountviewModel();
  private submitted = false;
  public component_Title = 'Purchase';
  public isPreview: boolean = false;
  public thumbnail: any;
  public nowDate: Date;
  public ComName: any;
  public Address: any;
  public contect: any;

  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'RefId',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  public accountDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Acc_Titel',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };

  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public citemtype = 'itemtype';
  public itemTypes: any = [];

  public suppliers: any = [];
  public csuppliers = 'suppliers';
  public selectedSuppliers: any = [];

  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectspp : any;
  public wareHouseList:Array<any>=[];
  public whList:Array<any>=[];
  public purchaseRequisitions: any = [];
  public cpurchaseRequisitions = 'purchaseRequisitions';
  public selectedPurchaseRequisitions: any = [];
  public client: any;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService
  ) { }
  name = 'CP_Email';
  id = 'Id';
  checked = true;
  disabled = false;
  label = 'Toggle On/Off';
  labelledby = 'Some Other Text';
  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.getWareHouses();
    // debugger
    this.isPreview = false;
    this.getimage();
    if (this.vm == undefined) {
      this.vm = new G_PurchaseMasterViewModel();
      this.vm.TransactionDate = new Date();
      this.vm.PaymentType = 1;
      this.vm.PurchaseDetail = [];
      this.vm.Vat_Value = 15;
      this.selectedSpareParts = [];
      this.selectedPurchaseRequisitions = [];
      this.vm.FK_Sup_Cradit_Acc_Email = new ItemViewModel(0,"");
      this.getRefid();

    } else {
      let selectedSuplier : any = [];
      let selectedSaprePartsList:any = [];
      let selectedemail : any;
      if (this.vm.FK_Sup_Cradit_Acc != undefined && this.vm.FK_Sup_Cradit_Acc != null) {
        let sCA = new DefineAccountviewModel();
      sCA.Id = this.vm.FK_Sup_Cradit_Acc.Id;
      sCA.Acc_Titel = this.vm.FK_Sup_Cradit_Acc.Name;
      this.vmD.Acc_Titel = this.vm.FK_Dabit_Acc.Name;
      if (this.vm.FK_Sup_Cradit_Acc_Email != undefined && this.vm.FK_Sup_Cradit_Acc_Email != null) {
      sCA.Id = this.vm.FK_Sup_Cradit_Acc_Email.Id;
      sCA.CP_Email = this.vm.FK_Sup_Cradit_Acc_Email.Name;
      }
      selectedSuplier.push(sCA);
      this.selectedSuppliers = selectedSuplier;
      this.selectspp = selectedemail;
      this.vm.FK_Sup_Cradit_Acc = new ItemViewModel(this.vm.FK_Sup_Cradit_Acc.Id,this.vm.FK_Sup_Cradit_Acc.Name);
      this.vm.Vat_Value=5;
      }
      this.vm.PurchaseDetail.forEach(x=> {
        let partssSelected = new GItemDefiningViewModel();
        partssSelected.Id = x.FK_G_Item_Id.Id;
        partssSelected.Name = x.FK_G_Item_Id.Name;
        selectedSaprePartsList.push(partssSelected);
  });
  this.selectedSpareParts = selectedSaprePartsList;
  this.updateTotals();
    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Purchase Order';
    }
    this.sparePartVm = new GItemDefiningViewModel();
    this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(0, ' ');
    this.vm.Vat_Value = 15;
    this.nowDate = new Date();
    this.getItemTypes();
    this.getSuppliers();
    this.getRequisitions();
    this.getSpareParts();
    this.vatChanged();
    
  }

  getWareHouses(){
    this._uow.warehouseService.GetListByUserId().subscribe((res:any)=>{
      console.log('warehouse',res);
      this.wareHouseList = res;
      this.whList = res;
    });
  }
  onChange(e) {
    debugger
    if(this.selectedSuppliers.length>0){
      let item = this.selectspp;
      if(e== true){
        if (this.selectedSuppliers.length > 0 && this.suppliers != undefined) {
          let findSelected  = this.suppliers.find(x=> x.Id == this.selectedSuppliers[0].Id);
          let i = new G_PurchaseMasterViewModel();
          this.vm.FK_Sup_Cradit_Acc_Email = new ItemViewModel(findSelected.Id , findSelected.CP_Email);
          this.vm.IsEmailRequired = true;
        }
          //this.vm.IsEmailRequired = true;
          console.log(this.vm.FK_Sup_Cradit_Acc_Email);
      }
    }else{
      this.vm.FK_Sup_Cradit_Acc_Email = new ItemViewModel(0 , "");
      this.vm.IsEmailRequired = false;
    }
    // if(this.selectedSuppliers == 0){
    //   let item = this.selectspp;
    //   if(item != undefined){
    //     if(e== true){
    //         let i = new G_PurchaseMasterViewModel();
    //         this.vm.FK_Sup_Cradit_Acc_Email = new ItemViewModel(item.Id , item.CP_Email);
    //         console.log(this.vm.FK_Sup_Cradit_Acc_Email);
    //     }else{
    //       this.vm.FK_Sup_Cradit_Acc_Email = null;
    //     }
    //   }
    // }
}

  getRefid() {
    this._uow.purchaseOrderService.ValidatePurchsaeRefId().subscribe((res: any) => {
      console.log(res);
      this.vm.RefId = res.code;
    }, (error) => {
      console.log(error);
    });
  }

  getSupCashList() {
    this.selectedSuppliers = [];
    this.getSuppliers();
  }


  getSpareParts() {
    //'Spare-Parts'
    let supllierrr = "Spare-Parts";
    this._uow.itemService.GetSSItemDefiningByItemType(supllierrr).subscribe((res: any) => {
      this.spareParts = res;
      console.log(res);

    }, (err) => {
      this._uow.logger.log(err);
      this.spareParts = [];
    });
  }

  getItemTypes() {
    this._uow.itemTypeService.GetList().subscribe((res: any) => {
      this.itemTypes = res;
    }, (err) => this._uow.logger.log(err));
  }

  getSuppliers() {
    let supllier = "Supplier";
    if (this.vm.PaymentType === 2) {
      supllier = "Cash";
    }

    this._uow.supplierService.GetListSuplier(supllier).subscribe((res: any) => {
      this.suppliers = res.DtlList;
      this._uow.logger.log(this.suppliers);
    }, (err) => this._uow.logger.log(err));
  }

  getRequisitions() {
    this._uow.purchaseOrderService.GetList().subscribe((res: any) => {
      console.log(res.DtlList);
      this.purchaseRequisitions = res.DtlList;
    }, (error) => {
      console.log(error);
    });
  }

  PaymentTypeChanged() {

  }

  // spare parts
  onSelectSparePart(e) {
    debugger
    console.log(e);
    if (this.vm.PurchaseDetail == undefined || this.vm.PurchaseDetail.length == 0) {
      this.vm.PurchaseDetail = [];
    }
    let item = this.spareParts.find(x => x.Id === e.Id);
    console.log(item);
    if (item !== undefined) {
      let i = new PurchaseDetailViewModel();
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.MaxQuantity = item.MaxStock;
      i.AvalibelQuantity = item.CurrentStock;
      i.Quantity = item.MaxStock - item.CurrentStock;
      if(item.CurrentStock>item.MaxStock){
        i.Quantity = 0;
      }
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 15;
      this.quantityOrPriceChanged(i);
      this.vm.PurchaseDetail.push(i);
      this.vatChanged();
      this.updateTotals();

    }

  }

  onDeSelectSparePart(e) {
    let i = this.vm.PurchaseDetail.find(x => x.FK_G_Item_Id.Id === e.Id);
    if (i !== undefined) {
      this.vm.PurchaseDetail.splice(this.vm.PurchaseDetail.indexOf(i), 1);
    }
    this.updateTotals();
  }

  onSelectSupplier(e) {
     let item = this.suppliers.find(x => x.Id === e.Id);
    console.log(item);
    if (item !== undefined) {
      let i = new G_PurchaseMasterViewModel();
      this.vm.FK_Sup_Cradit_Acc = new ItemViewModel(item.Id , item.Acc_Titel);
      this.selectspp = item;
     //this.vm.FK_Sup_Cradit_Acc.Id = item.Id;
      //this.vm.FK_Sup_Cradit_Acc.Name = item.Acc_Titel;
      }
  }


  // input changes
  quantityOrPriceChanged(m) {
    m.TotalAmount = m.Quantity * m.PurchasePrice;
    this.vatChanged();
    this.updateTotals();
  }

  vatChanged() {
    if (this.vm.Vat_Value > -1) {
      if (this.vm.PurchaseDetail !== undefined && this.vm.PurchaseDetail.length > 0) {
        this.vm.PurchaseDetail.forEach(x => {
          x.VAT_Value = this.vm.Vat_Value;
          x.VatAmount = ((x.TotalAmount / 100) * this.vm.Vat_Value);
        });
      }
      this.updateTotals();
    }
  }

  vatAmountChanged(m) {
    this.updateTotals();
  }

  public TotalPayable = 0;
  public TotalVat = 0;
  public TotalPayableAfterVat = 0;
  updateTotals() {
    this.TotalPayableAfterVat = 0;
    this.TotalVat = 0;
    this.TotalPayable = 0;
    if (this.vm.PurchaseDetail !== undefined && this.vm.PurchaseDetail.length > 0) {
      this.vm.PurchaseDetail.forEach(x => {
        this.TotalPayable += x.TotalAmount;
        this.TotalVat += x.VatAmount;
        this.TotalPayableAfterVat += (x.TotalAmount + x.VatAmount);
        this.vm.Inv_Amount = this.TotalPayableAfterVat;
      });
    }
  }

  // purchase requisition change
  onSelectPurchaseRequisition(e) {
    debugger
    let selectedSaprePartsList: any = [];
    let selectedSuplier: any = [];
    let selectedWareHouseList: any = [];
    let f = this.purchaseRequisitions.find(x => x.Id == e.Id);
    if (f !== undefined) {
      console.log('selected purchase order',f);
      let s = f;
      //this.vm = new G_PurchaseMasterViewModel();
      this.vm = s;
    // this.vm.FK_WareHouse_Id=s.Fk_WareHouse_Id;
    this.vm.FK_WareHouse_Id = new ItemViewModel(s.Fk_WareHouse_Id.Id, s.Fk_WareHouse_Id.Name) ;
      this.vm.IsLinked = true;
      this.vm.Inv_Amount = this.TotalPayableAfterVat;
      this.vm.TransactionDate = this._uow.defaultService.CutTimeZone(f.TransactionDate);
      this.vm.PaymentType = 1;
      this.vm.Vat_Value = 15;
      if (s.Fk_WareHouse_Id != null && s.Fk_WareHouse_Id != undefined) {
        let sWH = new G_WareHouseViewModel();
        this.vm.FK_WareHouse_Id = new ItemViewModel(s.Fk_WareHouse_Id.Id ,s.Fk_WareHouse_Id.Name );
        
        selectedWareHouseList.push({FK_WareHouse_Id:this.vm.FK_WareHouse_Id});
        this.wareHouseList = selectedWareHouseList;
      }
      if (f.FK_Sup_Cradit_Acc != undefined && f.FK_Sup_Cradit_Acc != null) {
        let sCA = new DefineAccountviewModel();
      sCA.Id = f.FK_Sup_Cradit_Acc.Id;
      sCA.Acc_Titel = f.FK_Sup_Cradit_Acc.Name;
      selectedSuplier.push(sCA);
      this.selectedSuppliers = selectedSuplier;
      this.vm.FK_Sup_Cradit_Acc = new ItemViewModel(f.FK_Sup_Cradit_Acc.Id,f.FK_Sup_Cradit_Acc.Name);
      }
      if (this.vm.PurchaseDetail !== undefined && this.vm.PurchaseDetail.length > 0) {
        this.vm.PurchaseDetail.forEach(x => {
          //x.TotalAmount += x.Quantity * x.PurchasePrice;
          //this.selectedSpareParts.push(new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name));
          let partssSelected = new GItemDefiningViewModel();
              partssSelected.Id = x.FK_G_Item_Id.Id;
              partssSelected.Name = x.FK_G_Item_Id.Name;
              selectedSaprePartsList.push(partssSelected);
        });
        this.selectedSpareParts = selectedSaprePartsList;
        this.vatChanged();
        console.log(this.selectedPurchaseRequisitions);
      }
    }
    this.updateTotals();
  }

  onDeSelectPurchaseRequisition(e) {
    this.selectedSpareParts = [];
    this.selectedSuppliers = [];
    this.wareHouseList =this.whList;
    //let selectedSaprePartsList: any = [];
    // let f = this.purchaseRequisitions.find(x => x.Id == e.Id);
    // if (f !== undefined) {
    //   f.PurchaseDetail.forEach(x => {
    //     this.vm.PurchaseDetail.splice(this.vm.PurchaseDetail.indexOf(x),1);
    //   });
    // }
    // this.vm.PurchaseDetail.forEach(x=> {
    //   let partssSelected = new GItemDefiningViewModel();
    //           partssSelected.Id = x.FK_G_Item_Id.Id;
    //           partssSelected.Name = x.FK_G_Item_Id.Name;
    //           selectedSaprePartsList.push(partssSelected);
    // });
    // this.selectedSpareParts = selectedSaprePartsList;
    if (this.selectedPurchaseRequisitions.length == 0) {
      this.vm = new G_PurchaseMasterViewModel();
      this.vm.TransactionDate = new Date();
       this.getRefid();
      this.updateTotals();
    }
  }

  onRemoveFromDetailList(e) {
    this.selectedSpareParts = [];
    console.log(e);
    let i = this.vm.PurchaseDetail.find(x => x.FK_G_Item_Id.Id === e.FK_G_Item_Id.Id);
    if (i !== undefined) {
      this.vm.PurchaseDetail.splice(this.vm.PurchaseDetail.indexOf(i), 1);
      this.vm.PurchaseDetail.forEach(x => {
        this.selectedSpareParts.push(new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name));
      });
      if (this.vm.PurchaseDetail.length == 0) {
        this.selectedPurchaseRequisitions = [];
        this.getRefid();
      }
    }

    this.getRequisitions();
  }

  getimage()
  {
    this._uow.settingservice.GetByImage().subscribe((baseImage: any) =>{
      console.log(baseImage);
      if (baseImage != undefined &&  baseImage!==null) {
        if (baseImage.Attachment != undefined &&  baseImage.Attachment !==null) {

          this.thumbnail = "https://garagedesks.s3.eu-central-1.amazonaws.com/" + baseImage.Attachment;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }else{
          this.thumbnail = baseImage.ComName;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }
      }

    },(err)=>{
      console.log(err);
    })
  }

  // exteral coponents
  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }

  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }

  public itemType = new ItemViewModel();
  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {

    if (model !== undefined) {
      this.sparePartVm = model;
    } else {
      this.sparePartVm = undefined;
    }
    this.openModal(template, 1);
    this.modalRef.setClass('modal-xl');
  }

  openSaveOrUpdateModelForItemType(template: TemplateRef<any>, model?, itemType?) {
    this.itemType = this.itemTypes[itemType];
    this.sparePartVm.FK_G_ItemType_Id = new ItemViewModel(this.itemType.Id, this.itemType.Name);
    console.log(this.itemTypes);
    this.openSaveOrUpdateModel(template, model);
  }



  saveOrUpdate() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.onChange(true);
    this._uow.purchaseService
      .Post(this.vm)
      .toPromise().then((res: any) => {
        debugger
        console.log(res);
        this.submitted = true;
        this.vm = res;
        this.vm.IsEmailRequired=true;
        this.bsmRef.hide();
        this.modalRef.hide();
        this.onSubmitted.emit(this.submitted);
        this.spinner.hide();
        Swal.fire(
          'Created!',
          'Purchase Created/Updated!',
          'success'
        )
      })
      .catch((error) => {
        debugger
        Swal.fire(
          'Failure!',
          'Purchase Not Updated!',
          'error'
        )
        console.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(this.submitted);
        this.spinner.hide();
      });
  }

  navigateClick(){
    this.isPreview = false;
    this.modalRef.hide()
  }

  // external components
  onItemSubmitted(e, mf) {
    this._uow.logger.log(e);
    if (e === true) {

    }
  }

  openPreview(content) {
    debugger
    this.modalRef = this._uow.modalServiceEx.show(content);
    this.modalRef.setClass('modal-lg');
    this.isPreview = true;
  }
}
