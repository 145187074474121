import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { HttpClient } from '@angular/common/http';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { G_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Installment_View_Model';
import { parse } from 'querystring';
import { NgForm } from '@angular/forms';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { G_Bulk_Installment_View_Model } from 'src/app/viewmodels/g-purchase/G_Bulk_Installment_View_Model';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';

@Component({
  selector: 'app-purchase-installment',
  templateUrl: './purchase-installment.component.html',
  styleUrls: ['./purchase-installment.component.scss']
})
export class PurchaseInstallmentComponent implements OnInit {

  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();
  public SortBy:string = "asc";
  public modalRef: BsModalRef;
  public vm: G_PurchaseMasterViewModel = new G_PurchaseMasterViewModel();
  public vmS: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmListItems: any = [];
  public vmDirectList: any = [];
  public vmListItem: any = [];
  public vmD: DefineAccountviewModel = new DefineAccountviewModel();
  public vmI : G_Installment_View_Model =  new G_Installment_View_Model();
  public bulkInstallment: G_Bulk_Installment_View_Model = new G_Bulk_Installment_View_Model();
  public bulkbyid: any = [];
  p: number = 1;
  dataTable: any;
  @Input('dataTable') table;
  dtOption: any = {};
  public component_Title = 'Installment History';
  public refid: number;
  public total: number;
  public paidamount: number;
  public balance: number;
  public calingUrl: string;
  public urlId: number;
  public isPreview: boolean = false;
  private submitted = false;
  public suppliers: any = [];
  public selectedSuppliers: any = [];
  public selectedSpareParts: any = [];
  public accountList : ItemViewModel[]=[];
  public purchaseRequisitions: any = [];
  public cpurchaseRequisitions = 'purchaseRequisitions';
  public selectedPurchaseRequisitions: any = [];
  public nowDate: Date;
  public accountDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public isEdit= false;
  public client: any;
  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private _localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.nowDate = new Date();
    this.vmI.TransactionDate = new Date();
    if (this.vm == undefined) {
      this.vm = new G_PurchaseMasterViewModel();
      this.vm.TransactionDate = new Date();
      this.vm.PaymentType = 1;
      this.vm.PurchaseDetail = [];
      this.vm.Vat_Value = 0;
      this.vm.Inv_Amount = 0;
      this.vm.PaidAmount = 0;
      this.getRefid();
    }
    if (this.vmI == undefined) {
      this.vmI = new G_Installment_View_Model();
      this.vmI.TransactionDate = new Date();
      this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel;
      this.vmI.G_Fk_DabitAccount_Id.Id = 0;
      this.vmI.G_Fk_DabitAccount_Id.Name = "";
      this.vmI.G_FK_Purchase_Id = new ItemViewModel;
      this.vmI.G_FK_Purchase_Id.Id = 0;
      this.vmI.G_FK_Purchase_Id.Name = "";
      this.vmI.G_FK_Sale_Id = new ItemViewModel;
      this.vmI.G_FK_Sale_Id.Id = 0;
      this.vmI.G_FK_Sale_Id.Name = "";
      this.vmI.G_Fk_CreditAccount_Id = new ItemViewModel;
      this.vmI.G_Fk_CreditAccount_Id.Id = 0;
      this.vmI.G_Fk_CreditAccount_Id.Name = "";
      this.vmI.Amount = 0;
      this.vmI.Remarks = "";
      this.vmI.Id = 0;
      this.vmI.FK_Client_Id = new ItemViewModel(0, ' ');
      this.vmI.IsPurchase = false;
      this.vmI.Attachement ="";
    }
    if (this.vmS == undefined) {
      this.vmS = new G_SaleMasterViewModel();
      this.vmS.TransactionDate = new Date();
      this.vmS.PaymentType = 1;
      this.vmS.SaleDetails = [];
      this.vmS.FK_Customer_Acc = new ItemViewModel();
      this.vmS.FK_Customer_Acc.Id = 0;
      this.vmS.FK_Customer_Acc.Name ="";
      this.getRefSId();
    }
    this.calingUrl = this._router.url;
    this.route.queryParams.subscribe(params => {
    this.urlId = params['id'];
    });
    this.getAccount(' ', 'CaB');


    if (this.calingUrl.startsWith('/purchase')) {
      this._jq.showSpinner(true);
      debugger
      this._uow.purchaseService.GetById(this.urlId).subscribe((res: any)=>{
        this.vmListItem = res;
        this.vm = res;
        this.getList();
        console.log(res);
        this._jq.showSpinner();
      },(err)=>{
        console.log(err);
      })
    }

    if (this.calingUrl.startsWith('/direct-sale-dtl-view')) {
     // this._jq.showSpinner(true);
      debugger
      this._uow.saleService.GetById(this.urlId).subscribe((res: any)=>{
        this.vmListItem = res;
        this.vmS = res;
        this.getSaleList();
        console.log(res);
        this._jq.showSpinner();
      },(err)=>{
        console.log(err);
      })
    }

    if (this.calingUrl.startsWith('/direct-sale-list')) {
      ///this._jq.showSpinner(true);
      debugger
      this._uow.saleService.GetById(this.urlId).subscribe((res: any)=>{
        this.vmListItem = res;
        this.vmS = res;
        this.getSaleList();
        console.log(this.vmS);
        //this._jq.showSpinner();
      },(err)=>{
        console.log(err);
      })
    }

    if (this.calingUrl.startsWith('/payment-status')) {
      this._jq.showSpinner(true);
      debugger
      this._uow.saleService.GetById(this.urlId).subscribe((res: any)=>{
        this.vmListItem = res;
        this.vmS = res;
        this.getSaleList();
        console.log(res);
        this._jq.showSpinner();
      },(err)=>{
        console.log(err);
      })
    }

  }

  getList(){
    this._jq.showSpinner(true);
      debugger

    this._uow.purchaseService.GetInstallmentList(this.urlId).subscribe((res: any)=>{
      this.vmListItems = res;
      console.log(res);
      this._jq.showSpinner();
    },(err)=>{
      console.log(err);
    })
  }

  getSaleList(){
    this._jq.showSpinner(true);
      debugger
    this._uow.purchaseService.GetInstallmentSaleList(this.urlId).subscribe((res: any)=>{
      this.vmListItems = res;
      console.log(res);
      this._jq.showSpinner();
    },(err)=>{
      console.log(err);
    })
  }

  getLatestPurchaseData(){
    this._jq.showSpinner(true);
      debugger
    this._uow.purchaseService.GetById(this.urlId).subscribe((res: any)=>{
      this.vmListItem = res;
      this.vm = res;
      console.log(res);
      this._jq.showSpinner();
    },(err)=>{
      console.log(err);
    })
  }

  hide(){
    this.bsmRef.hide();
    if (this.calingUrl.startsWith('/purchase')) {
      this._router.navigate(['/purchase']);
      window.location.reload();
    }

    if (this.calingUrl.startsWith('/direct-sale-dtl-view')) {
      this._router.navigate(['/direct-sale-list']);
      window.location.reload();
    }

    if (this.calingUrl.startsWith('/direct-sale-list')) {
      this._router.navigate(['/direct-sale-list']);
    window.location.reload();
    }

    if (this.calingUrl.startsWith('/payment-status')) {
      this._router.navigate(['/payment-status']);
    window.location.reload();
    }

  }

  getLatestSaleData(){
    this._jq.showSpinner(true);
      debugger
    this._uow.saleService.GetById(this.urlId).subscribe((res: any)=>{
      this.vmListItem = res;
      this.vmS = res;
      console.log(res);
      this._jq.showSpinner();
    },(err)=>{
      console.log(err);
    })
  }

getAccount(x: string, event: string) {
debugger
  this._uow.accountsService.getAccountBySearch(x, event).subscribe((res: any) => {
    if (res) {
      this.accountList = res;
      console.log(res);
    }
  }, err => {
    console.log(err);
  });
}

openView(template: TemplateRef<any>,b, model?) {
  this.openModalLg(template);
  let id = b.FK_G_BulkPayment_Id.Id;
  debugger
  this._uow.saleService.GetBulkById(id).subscribe((res: any) => {
    this.bulkbyid = res;
  }, (err) => {
    console.log(err);
   });
}


getSuppliers() {
  let supllier = "Supplier";
  if (this.vm.PaymentType === 2) {
    supllier = "Cash";
  }

  this._uow.supplierService.GetListSuplier(supllier).subscribe((res: any) => {
    this.suppliers = res.DtlList;
    this._uow.logger.log(this.suppliers);
  }, (err) => this._uow.logger.log(err));
}

getRefid() {
  this._uow.purchaseOrderService.ValidatePurchsaeRefId().subscribe((res: any) => {
    console.log(res);
    this.vm.RefId = res.code;
  }, (error) => {
    console.log(error);
  });
}

getRefSId(){
  this._uow.saleService.ValidateG_SaleRefId().subscribe((res: any) =>{
    console.log(res);
    this.vm.RefId = res.Code;
  },(err)=>{
    console.log(err);
  })
}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
  }

  openModalLg(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-xl');
  }

  navigateClick(){
    this.isPreview = false;
    this.modalRef.hide()
  }

  public acountData:ItemViewModel;
  onSelectSupplier(e) {
    debugger
    console.log(e.target.value);
    let item = this.accountList.find(x => x.Id === parseInt(e.target.value));
    //  let i = new G_Installment_View_Model();
    //  i.G_Fk_DabitAccount_Id = new ItemViewModel();
    //  i.G_Fk_DabitAccount_Id.Id = item.Id;
    //  i.G_Fk_DabitAccount_Id.Name = item.Name;
    this.acountData = new ItemViewModel();
    this.acountData.Id = item.Id;
    this.acountData.Name = item.Name;
 }

 public attachfile(event) {
  this.vmI.file = event.target.files[0];
}

  saveOrUpdate() {

   if (this.acountData ==undefined){
      return;
    }
    this._jq.showSpinner(true);
    debugger
    if (this.calingUrl.startsWith('/purchase')) {
      this.vmI.G_FK_Purchase_Id = new ItemViewModel();
      this.vmI.G_FK_Purchase_Id.Id = this.vm.Id;
      this.vmI.G_FK_Purchase_Id.Name = "";
      this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel();
      this.vmI.G_Fk_DabitAccount_Id.Id = this.vm.FK_Sup_Cradit_Acc.Id;
      this.vmI.G_Fk_DabitAccount_Id.Name = this.vm.FK_Sup_Cradit_Acc.Name;
      this.vmI.G_Fk_CreditAccount_Id = new ItemViewModel();
      this.vmI.G_Fk_CreditAccount_Id.Id = this.acountData.Id;
      this.vmI.G_Fk_CreditAccount_Id.Name = this.acountData.Name;
      this._uow.purchaseService
        .PostInstallment(this.vmI)
        .toPromise().then((res: any) => {
          this.submitted = true;
          console.log(res);
          this._jq.showSpinner();
          this.onSubmitted.emit(this.submitted);
          this.getLatestPurchaseData();
          this.getList();

          Swal.fire(
            'Created!',
            'Installment Added/Updated!',
            'success'
          )
          this.clearform();
        })
        .catch((error) => {
          Swal.fire(
            'Failure!',
            'Insallment Not Added/Updated!',
            'error'
          )
          this.getList();
          console.log(`Promise rejected with ${JSON.stringify(error)}`);
          this.onSubmitted.emit(false);
        });
      }
    if (this.calingUrl.startsWith('/direct-sale-dtl-view')) {
      debugger
      this.vmI.G_FK_Sale_Id = new ItemViewModel();
      this.vmI.G_FK_Sale_Id.Id = this.vmS.Id;
      this.vmI.G_FK_Sale_Id.Name = "";
      this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel();
      this.vmI.G_Fk_DabitAccount_Id.Id = this.acountData.Id;
      this.vmI.G_Fk_DabitAccount_Id.Name = this.acountData.Name;
      this.vmI.G_Fk_CreditAccount_Id = new ItemViewModel();
      this.vmI.G_Fk_CreditAccount_Id.Id = this.vmS.FK_Customer_Acc.Id;
      this.vmI.G_Fk_CreditAccount_Id.Name = this.vmS.FK_Customer_Acc.Name;
      this._uow.purchaseService
        .PostInstallment(this.vmI)
        .toPromise().then((res: any) => {
          this.submitted = true;
          console.log(res);
          this.onSubmitted.emit(this.submitted);
          this.getLatestSaleData();
          this.getSaleList();

          Swal.fire(
            'Created!',
            'Installment Added/Updated!',
            'success'
          )
          this.clearform();
        })
        .catch((error) => {
          Swal.fire(
            'Failure!',
            'Insallment Not Added/Updated!',
            'error'
          )
          this.getList();
          console.log(`Promise rejected with ${JSON.stringify(error)}`);
          this.onSubmitted.emit(false);
        });
    }

    if (this.calingUrl.startsWith('/direct-sale-list')) {
      debugger
      this.vmI.G_FK_Sale_Id = new ItemViewModel();
      this.vmI.G_FK_Sale_Id.Id = this.vmS.Id;
      this.vmI.G_FK_Sale_Id.Name = "";
      this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel();
      this.vmI.G_Fk_DabitAccount_Id.Id = this.acountData.Id;
      this.vmI.G_Fk_DabitAccount_Id.Name = this.acountData.Name;
      this.vmI.G_Fk_CreditAccount_Id = new ItemViewModel();
      this.vmI.G_Fk_CreditAccount_Id.Id = this.vmS.FK_Customer_Acc.Id;
      this.vmI.G_Fk_CreditAccount_Id.Name = this.vmS.FK_Customer_Acc.Name;
      this.vmI.TransactionDate = new Date(this.vmI.TransactionDate);
      this._uow.purchaseService
        .PostInstallment(this.vmI)
        .toPromise().then((res: any) => {
          this.submitted = true;
          console.log(res);
          this.onSubmitted.emit(this.submitted);
          this.getLatestSaleData();
          this.getSaleList();
          Swal.fire(
            'Created!',
            'Installment Added/Updated!',
            'success'
          )
          this.clearform();
        })
        .catch((error) => {
          Swal.fire(
            'Failure!',
            'Insallment Not Added/Updated!',
            'error'
          )
          this.getList();
          console.log(`Promise rejected with ${JSON.stringify(error)}`);
          this.onSubmitted.emit(false);
        });
    }

    if (this.calingUrl.startsWith('/payment-status')) {
      this.vmI.G_FK_Sale_Id = new ItemViewModel();
      this.vmI.G_FK_Sale_Id.Id = this.vmS.Id;
      this.vmI.G_FK_Sale_Id.Name = "";
      this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel();
      this.vmI.G_Fk_DabitAccount_Id.Id = this.acountData.Id;
      this.vmI.G_Fk_DabitAccount_Id.Name = this.acountData.Name;
      this.vmI.G_Fk_CreditAccount_Id = new ItemViewModel();
      this.vmI.G_Fk_CreditAccount_Id.Id = this.vmS.FK_Customer_Acc.Id;
      this.vmI.G_Fk_CreditAccount_Id.Name = this.vmS.FK_Customer_Acc.Name;
      this._uow.purchaseService
        .PostInstallment(this.vmI)
        .toPromise().then((res: any) => {
          this.submitted = true;
          console.log(res);
          this.onSubmitted.emit(this.submitted);
          this.getLatestSaleData();
          this.getSaleList();

          Swal.fire(
            'Created!',
            'Installment Added/Updated!',
            'success'
          )
          this.clearform();
        })
        .catch((error) => {
          Swal.fire(
            'Failure!',
            'Insallment Not Added/Updated!',
            'error'
          )
          this.getList();
          console.log(`Promise rejected with ${JSON.stringify(error)}`);
          this.onSubmitted.emit(false);
        });
    }

  }

  clearform(){
    this.vmI = new G_Installment_View_Model();
    this.vmI.TransactionDate = new Date();
    this.vmI.Amount = 0;
    this.vmI.Remarks = "";
    this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel(0,"");
    this.acountData = new ItemViewModel(0,"");
  }
  EditPurchaseInstallment(b) {
    debugger
    if (this.calingUrl.startsWith('/purchase')) {
      this.vmI.Id = b.Id;
      this.vmI.TransactionDate = new Date(b.TransactionDate);
      this.vmI.Amount = b.Amount;
      this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel(b.G_Fk_DabitAccount_Id.Id, b.G_Fk_DabitAccount_Id.Name);
      // this.instalment.FK_Account.Id = item.PaidFrom.Id;
      // this.instalment.FK_Account.Name = item.PaidFrom.Name;
      this.vmI.Remarks = b.Remarks;
      this.vmI.Attachement = b.Attachement;
      this.isEdit = true;
      }

    if (this.calingUrl.startsWith('/direct-sale-dtl-view')) {
      this.vmI.Id = b.Id;
      this.vmI.TransactionDate = new Date(b.CreationDate);
      this.vmI.Amount = b.Amount;
      this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel(b.G_Fk_DabitAccount_Id.Id, b.G_Fk_DabitAccount_Id.Name);
      // this.instalment.FK_Account.Id = item.PaidFrom.Id;
      // this.instalment.FK_Account.Name = item.PaidFrom.Name;
      this.vmI.Remarks = b.Remarks;
      this.vmI.Attachement = b.Attachement;
    }

    if (this.calingUrl.startsWith('/direct-sale-list')) {
      this.vmI.Id = b.Id;
      this.vmI.TransactionDate = new Date(b.TransactionDate);
      this.vmI.Amount = b.Amount;
      this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel(b.G_Fk_DabitAccount_Id.Id, b.G_Fk_DabitAccount_Id.Name);
      // this.instalment.FK_Account.Id = item.PaidFrom.Id;
      // this.instalment.FK_Account.Name = item.PaidFrom.Name;
      this.vmI.Remarks = b.Remarks;
      this.vmI.Attachement = b.Attachement;
    }

    if (this.calingUrl.startsWith('/payment-status')) {
      this.vmI.Id = b.Id;
      this.vmI.TransactionDate = new Date(b.CreationDate);
      this.vmI.Amount = b.Amount;
      this.vmI.G_Fk_DabitAccount_Id = new ItemViewModel(b.G_Fk_DabitAccount_Id.Id, b.G_Fk_DabitAccount_Id.Name);
      // this.instalment.FK_Account.Id = item.PaidFrom.Id;
      // this.instalment.FK_Account.Name = item.PaidFrom.Name;
      this.vmI.Remarks = b.Remarks;
      this.vmI.Attachement = b.Attachement;
    }
  }

  conformDelete() {

    if (this.calingUrl.startsWith('/purchase')) {
      this._uow.purchaseService.DeleteInstallment(this.vmI).subscribe((res: any) => {
        this.getList();
        this.getLatestPurchaseData();
        Swal.fire(
          'Deleted!',
          'Installment Deleted!',
          'success'
        )
        this.modalRef.hide();
      }, (err) => {
        console.log(err);
        Swal.fire(
          'Failure!',
          'Installment Not Deleted!',
          'error'
        )
       });
      }

    if (this.calingUrl.startsWith('/direct-sale-dtl-view')) {
      this._uow.purchaseService.DeleteInstallment(this.vmI).subscribe((res: any) => {
        this.getSaleList();
        this.getLatestSaleData();
        Swal.fire(
          'Deleted!',
          'Installment Deleted!',
          'success'
        )
        this.modalRef.hide();
      }, (err) => {
        console.log(err);
        Swal.fire(
          'Failure!',
          'Installment Not Deleted!',
          'error'
        )
       });
    }

    if (this.calingUrl.startsWith('/direct-sale-list')) {
      this._uow.purchaseService.DeleteInstallment(this.vmI).subscribe((res: any) => {
        this.getSaleList();
        this.getLatestSaleData();
        Swal.fire(
          'Deleted!',
          'Installment Deleted!',
          'success'
        )
        this.modalRef.hide();
      }, (err) => {
        console.log(err);
        Swal.fire(
          'Failure!',
          'Installment Not Deleted!',
          'error'
        )
       });
    }
    if (this.calingUrl.startsWith('/payment-status')) {
      this._uow.purchaseService.DeleteInstallment(this.vmI).subscribe((res: any) => {
        this.getSaleList();
        this.getLatestSaleData();
        Swal.fire(
          'Deleted!',
          'Installment Deleted!',
          'success'
        )
        this.modalRef.hide();
      }, (err) => {
        console.log(err);
        Swal.fire(
          'Failure!',
          'Installment Not Deleted!',
          'error'
        )
       });
    }
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);

  }

  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.vmI = new G_Installment_View_Model();
    }
  }

  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.vmI = model;
    }
  }
public amountCheck:boolean=false;
  onEnterAmount(){
    const balance =this.vmS.Inv_Amount - this.vmS.PaidAmount;
    const balances = this.vm.Inv_Amount - this.vm.PaidAmount
    if(this.vmI.Amount > balance ||this.vmI.Amount > balances){
      this.amountCheck = true;
       return;
    }
    if(this.vmI.Amount <= balance || this.vmI.Amount <= balances){
      this.amountCheck = false;
    }
  }
}
