import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { JqHelper } from 'src/app/common/jq-helper';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { PurchaseDetailViewModel } from 'src/app/viewmodels/g-purchase/PurchaseDetailViewModel';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { GPackagesModelViewModel } from 'src/app/viewmodels/g-packages/GPackagesModelViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { G_ComplainsSuggestionsViewModel } from 'src/app/viewmodels/g-settings/G_ComplainsSuggestionsViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { SaleDetailViewModel } from 'src/app/viewmodels/g-sale/SaleDetailViewModel';
import { G_PackagesTypeViewModel } from 'src/app/viewmodels/g-packages/G_PackagesTypeViewModel';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';

@Component({
  selector: 'app-repair-order-pdf',
  templateUrl: './repair-order-pdf.component.html',
  styleUrls: ['./repair-order-pdf.component.scss']
})
export class RepairOrderPdfComponent implements OnInit {
  modalRef: BsModalRef;
  disabled = true;
  @Input() public vmC: G_ComplainsSuggestionsViewModel;
  @Input() public vmD: DefineAccountviewModel;
  @Input() public vmP: GPackagesViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();  public component_Title = 'Sale';
  public vehicleDetail: any = [];
  public complaintsType: any = [];
  public ccomplaintsType: 'complaintsType';
  public packageTypes: any = [];
  public cvehicleDetail = 'vehicleDetail';
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public saleDetailVM: any = [];
  public services: any = [];
  public selectedServices: any = [];
  public cpackageTypes = 'packageTypes';
  public items: any = [];
  public brands: any = [];
  public selectedBrands: any = [];
  public models: any = [];
  public selectedModels: any = [];
  public citems: any = 'items';
  public cbrands: any = 'brands';
  public cmodels: any = 'models';
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectedPacakgeTypes: any = [];
  public tags: any = [];
  public selectedTagList: any;
  public selectedsaleDetail: any[];
  public saleDetail: any[];
  public csaleDetail: any[];
  public thumbnail: any;
  public client: any;

  constructor(private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private _localStorageService: LocalStorageService) {

  }

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.vm = new G_SaleMasterViewModel();
    this.saleDetailVM = new SaleDetailViewModel();
    //console.log(this.vm);
    this._jq.showSpinner(true);
    let id = this.route.snapshot.params['id'];
    this._uow.saleService.GetById(id).subscribe((res: any) =>{
      console.log(res);
      this.vm = res;

      console.log(res);
      this._jq.showSpinner();
    },(err)=>{
      console.log(err);
      this._jq.showSpinner();
    })
    this.getimage();

  }

  getimage()
  {
    this._jq.showSpinner(true);
    this._uow.settingservice.GetByImage().subscribe((baseImage: any) =>{
      console.log(baseImage);
      if (baseImage != undefined &&  baseImage!==null) {
        if (baseImage.Attachment != undefined &&  baseImage.Attachment !==null) {

          this.thumbnail = "https://garagedesks.s3.eu-central-1.amazonaws.com/" + baseImage.Attachment;
        }else{
          this.thumbnail = baseImage.ComName;
        }
      }

    },(err)=>{
      console.log(err);
      this._jq.showSpinner();
    })
  }

  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }

  openModalLg(template: TemplateRef<any>, itemtype?) {

    this.openModal(template);
    this.modalRef.setClass('modal-lg');
  }

  getTags() {
    this._jq.showSpinner(true);
    this._uow.tagsService.GetList().subscribe((res: any) =>
    { this.tags = res;
      this._jq.showSpinner();
    }, (err) =>
    {
      console.log(err);
      this._jq.showSpinner();
    });
  }

  onButtonClick() {

    this._jq.showSpinner(true);
    this._router.navigate(['/repair-order-list'])
  }

   // Tags
  onSelectTags(e) {
    if (this.vm.SalesTags == undefined || this.vm.SalesTags.length == 0) {
      this.vm.SalesTags = [];
    }
    this._uow.logger.log(e);
    let item = this.tags.find(x => x.Id === e.Id);
    if (item !== undefined) {

      let i = new G_TagsViewModel();
      i.Name = item.Name;
      // i.Tags_Type.Id = item.Id;
      // i.Tags_Type.Name = item.Name;
      i.FK_Tag_Id = item.Id;
      this.vm.SalesTags.push(i);
      // let a = new G_SaleMasterViewModel();
      // a.Id = item.Id;
      // a.Name = item.Name;
      // this.vm.SalesTags.push(i);

      }

  }

  onDeSelectTags(e) {
    let i = this.vm.SalesTags.find(x => x.Id === e.Id);
    if (i !== undefined) {
      this.vm.SalesTags.splice(this.vm.SalesTags.indexOf(i), 1);
    }

  }

  onItemSubmitted(e) {
    if (e == true) {
      //this.getList();
      this.getTags();
      this._jq.showSpinner(false);
    }
  }

  getSpareParts(e){
    this._uow.logger.log(e);

    let item = this.saleDetail.find(x => x.Id === e.id);
    if (item !== undefined) {
      // let a =  new GPackageItemViewModel();
      // a.Quantity = 1;
      // a.Fk_G_SSItemDefining_Id = e;
      // a.DescountPercentage = 0;
      // a.SalePrice = item.SalePrice;
      // a.Amount = 0;
      // a.Amount = item.SalePrice;
      item.SaleDetails.forEach(x => {

        if (x.FK_G_Item_Type_Id.Id === 18) {
          let selectedparts = this.spareParts.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);

          if (selectedparts !== undefined) {
            let i = new SaleDetailViewModel();
            i.Quantity = 1;
            i.FK_G_Item_Id = new ItemViewModel(selectedparts.Id, selectedparts.Name);
            i.FK_G_Item_Type_Id = new ItemViewModel(selectedparts.Id, selectedparts.Name);
            // i.AvalibelQuantity = item.CurrentStock;
            // i.Quantity = item.CurrentStock;
            i.Item_Code = selectedparts.Code;
            i.Item_Name = item.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.Item_Type = selectedparts.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            //this.vm.SaleDetails.push(i);
            let a =  new GPackageItemViewModel();
            a.Quantity = 1;
            a.Fk_G_SSItemDefining_Id = e;
            a.DescountPercentage = x.DescountPercentage;
            a.SalePrice = x.SalePrice;
            a.Amount = 0;
            a.Amount = x.SalePrice;
            a.DiscountAmount =  0;
            //this.vmP.SpareParts.push(a);
          }

        }

        if (x.FK_G_Item_Type_Id.Id === 19) {
          let selectedservices = this.services.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);
          if (selectedservices !== undefined) {
            let i = new SaleDetailViewModel();

            i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
            i.FK_G_Item_Type_Id = new ItemViewModel(selectedservices.Id, selectedservices.Name);
            //i.AvalibelQuantity = item.CurrentStock;
            i.Quantity = 1;
            i.Item_Code = selectedservices.Code;
            i.Item_Name = item.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.Item_Type = selectedservices.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            //this.vm.SaleDetails.push(i);
            let a =  new GPackageItemViewModel();
            a.Quantity = 1;
            a.Fk_G_SSItemDefining_Id = e;
            a.DescountPercentage = x.DescountPercentage;
            a.SalePrice = x.SalePrice;
            a.Amount = 0;
            a.Amount = x.SalePrice;
            //this.vmP.Services.push(a);
          }

        }
      });
       //this.vmP.SpareParts.push(a);
      }
  }


}
