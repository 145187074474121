import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { JqHelper } from 'src/app/common/jq-helper';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { PurchaseDetailViewModel } from 'src/app/viewmodels/g-purchase/PurchaseDetailViewModel';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { GPackagesModelViewModel } from 'src/app/viewmodels/g-packages/GPackagesModelViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { G_ComplainsSuggestionsViewModel } from 'src/app/viewmodels/g-settings/G_ComplainsSuggestionsViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { SaleDetailViewModel } from 'src/app/viewmodels/g-sale/SaleDetailViewModel';
import { G_PackagesTypeViewModel } from 'src/app/viewmodels/g-packages/G_PackagesTypeViewModel';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';
@Component({
  selector: 'app-repair-invoice',
  templateUrl: './repair-invoice.component.html',
  styleUrls: ['./repair-invoice.component.scss']
})
export class RepairInvoiceComponent implements OnInit {
  disabled = true;
  @Input() public vmC: G_ComplainsSuggestionsViewModel;
  @Input() public vmD: DefineAccountviewModel;
  @Input() public vmP: GPackagesViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  modalRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();  public component_Title = 'Sale';
  public vehicleDetail: any = [];
  public complaintsType: any = [];
  public ccomplaintsType: 'complaintsType';
  public packageTypes: any = [];
  public cvehicleDetail = 'vehicleDetail';
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmDetail: number = 15;
  public saleDetailVM: any = [];
  public services: any = [];
  public selectedServices: any = [];
  public cpackageTypes = 'packageTypes';
  public items: any = [];
  public brands: any = [];
  public selectedBrands: any = [];
  public models: any = [];
  public selectedModels: any = [];
  public citems: any = 'items';
  public cbrands: any = 'brands';
  public cmodels: any = 'models';
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectedPacakgeTypes: any = [];
  public tags: any = [];
  public selectedTagList: any;
  public selectedsaleDetail: any[];
  public saleDetail: any[];
  public csaleDetail: any[];
  submitted: boolean;
  public customerDetail: any = [];
  public selectedcustomerDetail: any = [];
  public DisableSave: boolean = false;
  public EnableNext: boolean = false;
  public isPreview: boolean = false;
  public thumbnail: any;
  public ComName: any;
  public Address: any;
  public contect: any;

  public brandsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public modelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Acc_Titel',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  public accountDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Acc_Titel',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };

  public itemTypes = [];
  public ssItemVm = new GItemDefiningViewModel();
  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'RegistraionNumber',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  files: File[] = [];
  public calingUrl: string;
  public urlId: number;
  public client: any;
  constructor(
    private route:ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService
  ) { }
  name = 'CP_Email';
  id = 'Id';
  checked = true;
  isdisabled = false;
  label = 'Toggle On/Off';
  labelledby = 'Some Other Text';

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.vmDetail = parseInt(this.client.Vat);
    let date = new Date();
    document.getElementById("date").innerHTML = date.toLocaleDateString();
    this.getimage();
    this.customerDetail = [];
    this.selectedcustomerDetail = [];
    this.vm = new G_SaleMasterViewModel();
    this.getCustomers();
    this.vm.FK_Customer_Acc_Email = new ItemViewModel(0,"");
    this.saleDetailVM = new SaleDetailViewModel();
    this.vm.Fk_G_StatusType_Id = new ItemViewModel(8,"");
    this.vm.Fk_G_Vechile_Id = new ItemViewModel(0,"");
    this.vm.Fk_G_Customer_Id = new ItemViewModel(0,"");
    this.vm.FK_Brand = new ItemViewModel(0,"");
    this.vm.FK_Model = new ItemViewModel(0,"");
    //console.log(this.vm);
    debugger
    let id = this.route.snapshot.params['Id'];
    this.calingUrl = this._router.url;
    this.route.queryParams.subscribe(params => {
      this.urlId = params['id'];
    });
    // this._jq.showSpinner(true);
    this.spinner.show();
    debugger
    this._uow.saleService.GetById(this.urlId).subscribe((res: any) =>{
      console.log(res);
      this.vm = res;
      // this._jq.showSpinner();
      this.spinner.hide();
      debugger
      this.vm.PaymentType = 1;
     // this.vm.FK_Customer_Acc_Email = new ItemViewModel(res.FK_Customer_Acc_Email.Id. res.FK_Customer_Acc_Email.Name)
      this.vatChanged();
      console.log(this.vm);

    },(err)=>{
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
    })

    if(this.vm.SalesTagsList !== undefined && this.vm.SalesTagsList.length > 0){
      this.vm.SalesTagsList.forEach(x => {
        this.selectedTagList.push(new ItemViewModel(x.Id,x.Name));
      });
    }
    this.getTags();
    this.DisableSave = false;
    this.isPreview = false;

  }

  onChange(e) {
    debugger
      let item = this.vm.FK_Customer_Acc_Email;
      if(item!=null){
        if(e== true){
            this.vm.FK_Customer_Acc_Email = new ItemViewModel(item.Id , item.Name);
      this.vm.IsEmailRequired == true;

        }else{
          this.vm.FK_Customer_Acc_Email = null;
      this.vm.IsEmailRequired == false;

        }
      }else{
        this.vm.FK_Customer_Acc_Email = new ItemViewModel(0,'');
      }
}

  onSelectCustomer(e) {
     let item = this.customerDetail.find(x => x.Id === e.Id);
    console.log(item);
    if (item !== undefined) {
      this.vm.FK_Customer_Acc = new ItemViewModel(item.Id , item.Acc_Titel);
      //this.vm.FK_Sup_Cradit_Acc.Id = item.Id;
      //this.vm.FK_Sup_Cradit_Acc.Name = item.Acc_Titel;
      }
  }

  getSupCashList() {
    this.vm.FK_Customer_Acc = new ItemViewModel();
    //this.getRegNo();
  this.selectedcustomerDetail = [];
  this.getCustomers();
}

getCustomers() {
  let Customer = "Customer";
  if (this.vm.PaymentType === 2) {
    Customer = "Cash";
  }
  if (this.vm.PaymentType === 3) {
    Customer = "Office";
  }
  this._uow.customerService.GetListSuplier(Customer).subscribe((res: any) => {
    debugger
    this.customerDetail = res.DtlList;
    if(this.customerDetail.length > 0){
      // // debugger;
      this.customerDetail.forEach(x => {
        x.Acc_Titel = x.Acc_Code + '-' + x.Acc_Titel;
      });
    }
  }, (error) => {
    this._uow.logger.log(error);
    this.customerDetail = [];
  });
}

	onSelect(e) {
		console.log(e);
    this.files.push(...e.addedFiles);

    this.vm.file = this.files;
	}

	onRemove(e) {
		console.log(e);
		this.files.splice(this.files.indexOf(e), 1);
	}

  getTags() {
    this._uow.tagsService.GetList().subscribe((res: any) => { this.tags = res; }, (err) => console.log(err));
  }

  // onButtonClick() {
  //
  //   this._uow.saleService
  //   .PostReady(this.vm)
  //   .toPromise().then((res: any) => {
  //     console.log(res);
  //     this.vm.Fk_G_StatusType_Id = res.Fk_G_StatusType_Id.Id;
  //     this.submitted = true;
  //     this.onSubmitted.emit(this.submitted);
  //     Swal.fire(
  //       'Saved!',
  //       'Repair Order Updated!',
  //       'success'
  //     )
  //     //this.bsmRef.hide();
  //     //alert("Repair Order Saved Successfully");
  //     //this._router.navigate(['/repair-order-list'])

  //     //this._document.defaultView.location.reload();
  //     this.bsmRef.hide();
  //     this._jq.showSpinner(true);
  //     this._router.navigate(['/updated-invoice',{id: this.vm.Id}])
  //   })
  //   .catch((error) => {
  //     Swal.fire(
  //       'Error!',
  //       'Repair Order Not Updated!',
  //       'warning'
  //     )
  //     this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
  //     this.onSubmitted.emit(false);
  //   });
  // }

   // Tags
  onSelectTags(e) {
    if (this.vm.SalesTags == undefined || this.vm.SalesTags.length == 0) {
      this.vm.SalesTags = [];
    }
    this._uow.logger.log(e);
    let item = this.tags.find(x => x.Id === e.Id);
    if (item !== undefined) {
      let i = new G_TagsViewModel();
      i.Name = item.Name;
      // i.Tags_Type.Id = item.Id;
      // i.Tags_Type.Name = item.Name;
      i.FK_Tag_Id = item.Id;
      this.vm.SalesTags.push(i);
      // let a = new G_SaleMasterViewModel();
      // a.Id = item.Id;
      // a.Name = item.Name;
      // this.vm.SalesTags.push(i);

      }

  }

  getimage()
  {
    this._uow.settingservice.GetByImage().subscribe((baseImage: any) =>{
      console.log(baseImage);
      if (baseImage != undefined &&  baseImage!==null) {
        if (baseImage.Attachment != undefined &&  baseImage.Attachment !==null) {

          this.thumbnail = "https://garagedesks.s3.eu-central-1.amazonaws.com/" + baseImage.Attachment;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }else{
          this.thumbnail = baseImage.ComName;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }
      }

    },(err)=>{
      console.log(err);
      this._jq.showSpinner();
    })
  }

  onDeSelectTags(e) {
    let i = this.vm.SalesTags.find(x => x.Id === e.Id);
    if (i !== undefined) {
      this.vm.SalesTags.splice(this.vm.SalesTags.indexOf(i), 1);
    }

  }

  onItemSubmitted(e) {
    if (e == true) {
      //this.getList();
      this.getTags();
      this._jq.showSpinner(false);
    }
  }

  getSpareParts(e){
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.logger.log(e);
    let item = this.saleDetail.find(x => x.Id === e.Id);
    if (item !== undefined) {
      // let a =  new GPackageItemViewModel();
      // a.Quantity = 1;
      // a.Fk_G_SSItemDefining_Id = e;
      // a.DescountPercentage = 0;
      // a.SalePrice = item.SalePrice;
      // a.Amount = 0;
      // a.Amount = item.SalePrice;
      item.SaleDetails.forEach(x => {

        if (x.FK_G_Item_Type_Id.Id === 18) {
          let selectedparts = this.spareParts.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);

          if (selectedparts !== undefined) {
            let i = new SaleDetailViewModel();
            i.Quantity = 1;
            i.FK_G_Item_Id = new ItemViewModel(selectedparts.Id, selectedparts.Name);
            i.FK_G_Item_Type_Id = new ItemViewModel(selectedparts.Id, selectedparts.Name);
            // i.AvalibelQuantity = item.CurrentStock;
            // i.Quantity = item.CurrentStock;
            i.Item_Code = selectedparts.Code;
            i.Item_Name = item.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.Item_Type = selectedparts.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            //this.vm.SaleDetails.push(i);
            let a =  new GPackageItemViewModel();
            a.Quantity = 1;
            a.Fk_G_SSItemDefining_Id = e;
            a.DescountPercentage = x.DescountPercentage;
            a.SalePrice = x.SalePrice;
            a.Amount = 0;
            a.Amount = x.SalePrice;
            a.DiscountAmount =  0;
            //this.vmP.SpareParts.push(a);
          }

        }

        if (x.FK_G_Item_Type_Id.Id === 19) {
          let selectedservices = this.services.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);
          if (selectedservices !== undefined) {
            let i = new SaleDetailViewModel();

            i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
            i.FK_G_Item_Type_Id = new ItemViewModel(selectedservices.Id, selectedservices.Name);
            //i.AvalibelQuantity = item.CurrentStock;
            i.Quantity = 1;
            i.Item_Code = selectedservices.Code;
            i.Item_Name = item.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.Item_Type = selectedservices.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            //this.vm.SaleDetails.push(i);
            let a =  new GPackageItemViewModel();
            a.Quantity = 1;
            a.Fk_G_SSItemDefining_Id = e;
            a.DescountPercentage = x.DescountPercentage;
            a.SalePrice = x.SalePrice;
            a.Amount = 0;
            a.Amount = x.SalePrice;
            //this.vmP.Services.push(a);
        // this._jq.showSpinner();
        this.spinner.hide();
          }

        }
      });
       //this.vmP.SpareParts.push(a);
      //  this._jq.showSpinner();
      this.spinner.hide();
      }
  }


  quantityChange(e, i?) {
    e.SalePrice = e.Quantity * e.DiscountAmount;
    //  if (e.DescountPercentage > 0) {
    //    e.Amount = (e.Amount - ((e.DescountPercentage / 100) * e.Amount));
    //  }
    this.vatChanged();
  }

  discountChange(e, i?) {
    let discountAMmount = ((e.DiscountAmount * e.SalePrice)) / 100 ;
    e.Discount =discountAMmount;
    e.DiscountAmount = e.SalePrice-e.Discount;
    e.SalePrice = e.DiscountAmount * e.Quantity;
    // if(this.vmP.DiscountAmount > 0){
    //
    //   this.calculateTotals();
    // }
    // if(this.vmP.DiscountAmount <= 0){
    //   let discountAMmount = ((e.DescountPercentage / 100) * e.Amount);
    //   e.Amount = (e.Amount - discountAMmount);
    //   this.calculateTotals();
    // }
    this.vatChanged();
  }

  amountChange(e, i?) {
    e.DiscountAmount = ((e.Discount * 100) / e.SalePrice);
    this.discountChange(e, i);
    // if(this.vmP.DiscountAmount > 0){
    //
    //   this.calculateTotals();
    // }
    // if(this.vmP.DiscountAmount < 0){
    //   let discountAMmount = (e.Amount-e.DescountAmmount);
    //   e.Amount = (discountAMmount);

    // }
    this.vatChanged();
  }

  public TotalPayable = 0;
  public TotalVat = 0;
  public TotalPayableAfterVat = 0;
  public DiscountTotal = 0;
  public partsAmount = 0;
  public serviceAmount = 0;
  calculateTotals() {
    this.TotalPayableAfterVat = 0;
    this.TotalVat = 0;
    this.DiscountTotal = 0;
    this.TotalPayable = 0;
    this.partsAmount = 0;
    this.serviceAmount = 0;
    this.vm.Services.forEach(x => {
      this.TotalVat += x.VatAmount;
      //x.TotalAmount = x.TotalAmount+this.TotalVat;
      this.serviceAmount += x.TotalAmount;
      if (x.DiscountAmount >= 0) {
        this.DiscountTotal += (x.DiscountAmount * x.Quantity);
      }
    });
    this.vm.SpareParts.forEach(x => {
      this.TotalVat += x.VatAmount;
      //x.TotalAmount = x.TotalAmount+this.TotalVat;
      this.partsAmount += x.TotalAmount;

      if (x.DiscountAmount >= 0) {
        this.DiscountTotal += (x.DiscountAmount * x.Quantity);
      }
    });
    this.vm.Inv_Amount = this.partsAmount +  this.serviceAmount +this.TotalVat;
    this.vm.TotalExpense = this.vm.Inv_Amount;
    this.vm.AllowedExpense = this.vm.Inv_Amount;

  }

  vatChanged() {
    debugger
    if (this.vmDetail > -1) {
      if (this.vm.SpareParts !== undefined && this.vm.SpareParts.length > 0) {
        this.vm.SpareParts.forEach(x => {
          x.VAT_Value = this.vmDetail;
          x.VatAmount = ((x.TotalAmount / 100) * this.vmDetail);
          x.TotalVatAmount = x.TotalAmount + x.VatAmount;
        });
      }
      if (this.vm.Services !== undefined && this.vm.Services.length > 0) {
        this.vm.Services.forEach(x => {
          x.VAT_Value = this.vmDetail;
          x.VatAmount =  ((x.TotalAmount / 100) * this.vmDetail);
          x.TotalVatAmount = x.TotalAmount + x.VatAmount;
        });
      }
      if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
        this.vm.SaleDetails.forEach(x => {
          x.VAT_Value = this.vmDetail;
          x.VatAmount = ((x.TotalAmount / 100) * this.vmDetail)
          x.TotalVatAmount =  x.TotalAmount + x.VatAmount;
        });
      }
      this.calculateTotals();
    }
  }

  vatAmountChanged(i) {
    this.calculateTotals();
  }


  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }

  openModalLg(template: TemplateRef<any>, itemtype?) {
    this.ssItemVm = new GItemDefiningViewModel();
    if (itemtype == 1) {
      let i = this.itemTypes.find(x => x.Name == 'Spare-Parts');
      console.log(i)
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    if (itemtype == 2) {
      let i = this.itemTypes.find(x => x.Name == 'Services');
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    this.openModal(template);
    this.modalRef.setClass('modal-lg');
  }

  OpenCreateDirectInvoice(e){
    this._router.navigate(['/updated-invoice', {Id: e}]);
  };

  saleCompleted(){
    // this._jq.showSpinner(true);
    this.spinner.show();
    let obj = this.itemVm;
    console.log();
    // this._jq.showSpinner();
    if(this.vm.PaymentType==1){
      this.onChange(true);
    }
    this.vm.IsEmailRequired == true;
    this.vm.Fk_G_StatusType_Id = new ItemViewModel(8,"SaleInvoice");
    this.vm.ServiceAmount = this.serviceAmount;
    this.vm.PartsAmount = this.partsAmount;
      // this._jq.showSpinner(true);
      this._uow.saleService
        .PostSave(this.vm)
        .toPromise().then((res: any) => {
          console.log(res);
          // this._jq.showSpinner();
          this.spinner.hide();
          this.submitted = true;
          this.onSubmitted.emit(this.submitted);
          Swal.fire(
            'Saved!',
            'Repair Order Updated!',
            'success'
          )
          this.EnableNext = true;

        })
        .catch((error) => {
          Swal.fire(
            'Error!',
            'Repair Order Not Updated!',
            'warning'
          )
          this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
          this.onSubmitted.emit(false);
          // this._jq.showSpinner();
          this.spinner.hide();
        });
  }

  saveOrUpdate() {
    // this._jq.showSpinner(true);
    let obj = this.itemVm;
    console.log();
    // this._jq.showSpinner();
    if(this.vm.PaymentType==1){
      this.onChange(true);
    }
    this.vm.IsEmailRequired == true;
      this.vm.Fk_G_StatusType_Id = new ItemViewModel(8,"SaleInvoice");
      //this.vm.Inv_Amount = this.vmP.PackageAmount
      this.vm.PaidAmount = this.vm.Inv_Amount;
      // this._jq.showSpinner(true);
    this.spinner.show();
      this._uow.saleService
        .Post(this.vm)
        .toPromise().then((res: any) => {
          console.log(res);
          // this._jq.showSpinner();
          this.spinner.hide();
          this.submitted = true;
          this.onSubmitted.emit(this.submitted);
          Swal.fire(
            'Saved!',
            'Repair Order Updated!',
            'success'
          )
          this.EnableNext = true;

        })
        .catch((error) => {
          Swal.fire(
            'Error!',
            'Repair Order Not Updated!',
            'warning'
          )
          this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
          console.log(error);
          this.onSubmitted.emit(false);
          // this._jq.showSpinner();
          this.spinner.hide();
        });
        this.modalRef.hide();
}

navigateClick(){
  // this.isPreview = false;
  this.modalRef.hide()
}

nextPage(item){
  debugger
  if(item.PaymentType == 1){
    alert("Hello");
  }else{
    alert("World");
  }
}

openPreview(content) {
  debugger
  this.modalRef = this._uow.modalServiceEx.show(content);
  this.modalRef.setClass('modal-lg');
  this.isPreview = true;
}

public amountCheck:boolean=false;
  onEnterAmount(){
    if(this.vm.AllowedExpense > this.vm.TotalExpense ){
      this.amountCheck = true;
       return;
    }
    if(this.vm.AllowedExpense <= this.vm.TotalExpense){
      this.amountCheck = false;
    }
  }
}
