import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { GQuotationDetail, GQuotationSaveModel, GQuotationTags } from 'src/app/viewmodels/g-quotation/QuotationModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { SaleDetailViewModel } from 'src/app/viewmodels/g-sale/SaleDetailViewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-quotation-form',
  templateUrl: './quotation-form.component.html',
  styleUrls: ['./quotation-form.component.scss']
})
export class QuotationFormComponent implements OnInit {

  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  modalRefLv3: BsModalRef;
  modalRef: BsModalRef;
  public vmListItems: any = [];
  @Input() public Id: any;
  @Input() public vmP: DefineAccountviewModel;
  @Input() public vmp: GPackagesViewModel;
  @Input() public modal_title: any;
  private submitted = false;
  public vehicleDetail: any = [];
  public selectedTagsList: any = [];
  public cvehicleDetail = "vehicleDetail";
  public selectedvehicleDetail: any = [];
  public userAssignedWareHouses: Array<any> = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmDetail: number;
  public totalExpense:number=0;
  public allowedExpense:number=0;
  public spareParts: Array<any> = [];
  public selectedSpareParts: any = [];
  bsmRef: any;
  @Output() onSubmitted = new EventEmitter<any>();
  public tags: any = [];
  public selectedTagList: any = [];
  public citems: any = "items";
  public selectedDate: any;
  public DisableSave: boolean = false;
  public EnableNext: boolean = false;
  public thumbnail: any;
  public ComName: any;
  public Address: any;
  public contect: any;
  public SelectedItemServices: any = [];
  public services: any = [];
  public selectedemail: any = [];
  public customerAccount: ItemViewModel;
  public brandsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  public modelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };

  public noteDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  public PackageSelectionDropdown: IDropdownSettings = {
    singleSelection: false,
    idField: "Id",
    textField: "Name",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    limitSelection: 1,
  };
  name = "CP_Email";
  id = "Id";
  checked = true;
  isdisabled = false;
  label = "Toggle On/Off";
  labelledby = "Some Other Text";

  public itemTypes = [];
  public ssItemVm = new GItemDefiningViewModel();
  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "RegistraionNumber",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "Acc_Titel",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    limitSelection: 1,
  };
  public customerDetail: any = [];
  public selectedcustomerDetail: any = [];
  public ccustomerDetail = "customerDetail";
  public selectedServices: any = [];
  public maxDate = new Date();
  public quotationSave: GQuotationSaveModel = new GQuotationSaveModel();
  public vehicleDetailObj: any; 
  public quotationNumber: any;
  public quotationSubject: any = "New Quotation";
  public noteslist: any = [];
  selectedNote:ItemViewModel = new ItemViewModel;
    noteText: string = "";
  public client: any;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    public _router: Router,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService,
    private route: ActivatedRoute
  ) {}

  files: File[] = [];
  public quoteNumberList: any;
  ngOnInit() {
    
    this.client = this._localStorageService.get("com");
    this.vmDetail = parseInt(this.client.Vat);

    this.Id = parseInt(this.route.snapshot.paramMap.get('id') || '0');
    if (!this.Id) {
      this.getQuoteNumbers();
    }
      

    this.getRegNo();
    this.getimage();
    this.getCustomers();
    this.getRequisitions();
    this.getTags();
    this.getServices();
    this.getQuoteNotes();
    if (
      this.itemVm.FK_Tag_List !== undefined &&
      this.itemVm.FK_Tag_List.length > 0
    ) {
      this.itemVm.FK_Tag_List.forEach((x) => {
        this.selectedTagList.push(new ItemViewModel(x.Id, x.Name));
      });
    }
    if (this.itemVm == undefined) {
      this.itemVm = new GVechileViewModel();
      this.vehicleDetail = [];
      this.itemVm.Fk_G_Customer_Discount = 0;
      this.itemVm.Fk_G_Customer_Id_Email = new ItemViewModel(0, "");
      this.selectedvehicleDetail = [];
    } else {
    }
    if (this.vm == undefined) {
      this.vm = new G_SaleMasterViewModel();
      this.vm.FK_Customer_Acc = new ItemViewModel(0, "");
    } else {
    }
    if (this.vmP == undefined) {
      this.vmP = new DefineAccountviewModel();
      this.customerDetail = [];
      this.selectedcustomerDetail = [];
    } else {
    }
    if (this.modal_title == undefined) {
      this.modal_title = "Sale";
    }
    if (this.vmp == undefined) {
      this.vmp = new GPackagesViewModel();
      this.vm = new G_SaleMasterViewModel();
      this.vm.TransactionDate = new Date();
      this.vmp.SpareParts = [];
      this.vmp.Services = [];
      this.vm.PaymentType = 1;
      this.vm.TransactionDate = new Date();
      this.vm.Fk_G_StatusType_Id = new ItemViewModel(8, "SaleInvoice");
      this.vm.FK_Customer_Acc_Email = new ItemViewModel(0, "");
    } else {
      if (
        this.vm.SalesTagsList !== undefined &&
        this.vm.SalesTagsList.length > 0
      ) {
        this.vm.SalesTagsList.forEach((x) => {
          this.selectedTagsList.push(new ItemViewModel(x.Id, x.Name));
        });
      }

      if (this.vmp.SpareParts !== undefined && this.vmp.SpareParts.length > 0) {
        this.vmp.SpareParts.forEach((x) => {
          this.selectedSpareParts.push(
            new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            )
          );
        });
      }
      if (this.vmp.Services !== undefined && this.vmp.Services.length > 0) {
        this.vmp.Services.forEach((x) => {
          this.selectedServices.push(
            new ItemViewModel(
              x.Fk_G_SSItemDefining_Id.Id,
              x.Fk_G_SSItemDefining_Id.Name
            )
          );
        });
      }
    }
    
    this.getUserAssingnedWareHouses().then((res) => {
      console.log("promise res", res);
      this.getSpareParts(this.vehicleDetail[0].FK_G_Model.Id,res[0].FK_WareHouse_Id.Id);
    });
 
  }


  getimage() {
    this._uow.settingservice.GetByImage().subscribe(
      (baseImage: any) => {
        console.log(baseImage);
        if (baseImage != undefined && baseImage !== null) {
          if (
            baseImage.Attachment != undefined &&
            baseImage.Attachment !== null
          ) {
            this.thumbnail =
              "https://fleetdesks.s3.eu-central-1.amazonaws.com/" +
              baseImage.Attachment;
            this.ComName = baseImage.ComName;
            this.Address = baseImage.Address;
            this.contect = baseImage.contect;
          } else {
            this.thumbnail = baseImage.ComName;
            this.ComName = baseImage.ComName;
            this.Address = baseImage.Address;
            this.contect = baseImage.contect;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getCustomers() {
  
    let Customer = "Customer";
    console.log(this.selectedcustomerDetail);
    this._uow.customerService.GetListSuplier(Customer).subscribe(
      (res: any) => {
        console.log(res);
        this.customerDetail = res.DtlList;
        if(this.customerDetail.length > 0){
          // // 
          this.customerDetail.forEach(x => {
            x.Acc_Titel = x.Acc_Code + '-' + x.Acc_Titel;
          });
        }
        this.vmP = res.DtlList;
        this.vmP.Acc_Code = res.Acc_Code;
        this.vmP.Acc_Titel = res.Acc_Code + "" + res.Acc_Titel;
        console.log(res);
      },
      (error) => {
        this._uow.logger.log(error);
        this.customerDetail = [];
      }
    );
  }

  getRequisitions() {
    console.log(this.selectedvehicleDetail);
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        // console.log(res);
        this.vehicleDetail = res;
      },
      (error) => {
        this._uow.logger.log(error);
        this.vehicleDetail = [];
      }
    );

    
  }

  getQuoteNumbers() {
    
    this._uow.quotationService.GetQuotationNumbers().subscribe(
      (res: any) => {
        // console.log(res);
        
        this.quoteNumberList = res.map(item => item.Numbers);
        this.quotationNumber = this.generateUniqueRandomString();
      },
      (error) => {
        this._uow.logger.log(error);
        this.vehicleDetail = [];
      }
    );
  }

  getQuoteNotes() {
    
    this._uow.quotationService.GetQuotationNotes().subscribe(
      (res: any) => {
        // console.log(res);
        debugger
        this.noteslist = res;
      },
      (error) => {
        this._uow.logger.log(error);
        
      }
    );
  }

  onNoteSelected() {
    debugger
    
    this.noteText = this.selectedNote[0].Name;
}

onNoteDeSelected() {
    this.noteText = "";
}



  onSelectvehicleDetail(e) {
    
    this._uow.logger.log(e);
    console.log('details',e);
    let selectedCustomerList: any = [];
    let selectedWareHouseList: any = [];
   
    let item = this.vehicleDetail.find((x) => x.Id === e.Id);
    this.vehicleDetailObj = this.vehicleDetail.find((x) => x.Id === e.Id);
    
    if (item !== undefined) {
      console.log(item);
      this.itemVm = item;
      this.itemVm.Name = item.Name;
      this.itemVm.RegistraionNumber = item.RegistraionNumber;
      this.itemVm.FK_Tag_List = item.FK_Tag_List;
      this.itemVm.Fk_G_Customer_Id.Id = item.Fk_G_Customer_Id.Id;
      this.itemVm.Fk_G_Customer_Id.Name = item.Fk_G_Customer_Id.Name;
      // this.vm.FK_WareHouse_Id = new ItemViewModel(item.FK_WareHouse_Id.Id,item.FK_WareHouse_Id.Name);
      this.customerAccount = new ItemViewModel(
        item.Fk_G_Customer_Id.Id,
        item.Fk_G_Customer_Id.Name
      );
      this.itemVm.Fk_G_Customer_Discount = item.Fk_G_Customer_Discount;
      if (this.itemVm.Fk_G_Customer_Id.Id != null) {
        let custList = new DefineAccountviewModel();
        custList.Id = this.itemVm.Fk_G_Customer_Id.Id;
        custList.Acc_Titel = this.itemVm.Fk_G_Customer_Id.Name;
        // this.itemVm.Fk_G_Customer_Id.Id =item.Id;
        // this.itemVm.Fk_G_Customer_Id.Name =item.Acc_Titel;
        custList.DiscountValue = this.itemVm.Fk_G_Customer_Discount;
        selectedCustomerList.push(custList);
        console.log(this.itemVm);
        this.selectedcustomerDetail = selectedCustomerList;
      } else {
        this.selectedcustomerDetail = null;
        let cd = document.getElementById("customerdetail");
        cd.classList.add("required");
      }
    }
  }

  onDeSelectvehicleDetail(e) {
    
    if (this.selectedvehicleDetail.length == 0) {
      this.itemVm = new GVechileViewModel();
      this.itemVm.RegistraionNumber = this.itemVm.RegistraionNumber;
      this.getRegNo();
      this.vmP = new DefineAccountviewModel();
      this.vmP.Id = this.vmP.Id;
      this.vmP.Acc_Titel = this.vmP.Acc_Titel;
      this.selectedcustomerDetail = [];
      this.getCustomerName();
    }
  }

  getRegNo() {
    this.spinner.show();
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        this.vehicleDetail = res;
        // this.itemVm.RegistraionNumber = res;
        console.log(res);
        this.spinner.hide();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this.spinner.hide();
      }
    );
  }

  getCustomerName() {
    this._uow.customerService.GetList().subscribe(
      (res: any) => {
        
        this.customerDetail = res.DtlList;
       
        // this.itemVm.RegistraionNumber = res;
        this._jq.showSpinner();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this._jq.showSpinner();
      }
    );
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template, 1);
    if (model !== undefined) {
      this.itemVm = model;
    } else {
      this.itemVm = new GVechileViewModel();
    }
  }

  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._modalService.show(template);
      this.modalRef.setClass("modal-lg");
    }
    if (lvl == 2) {
      this.modalRefLv2 = this._modalService.show(template);
    }
    if (lvl == 3) {
      this.modalRefLv3 = this._modalService.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }

  onSelectcustomerDetail(e) {
    let selectedCustomerL: any = [];
    this._uow.logger.log(e);
    let selectedVehicle: any = [];
      let item = this.customerDetail.find((x) => x.Id === e.Id);
      let item2 = this.vehicleDetail.find((y) => y.Id === e.Id);
      if (item !== undefined) {
        this.vmP = item;
        this.vmP.Id = item.Id;
        this.vmP.Acc_Titel = item.Acc_Titel;
        this.vmP.CP_Email = item.CP_Email;
        this.selectedemail.push(item);
        let VehList = new G_SaleMasterViewModel();
        VehList.Fk_G_Customer_Id = new ItemViewModel(0, "");
        VehList.Fk_G_Customer_Id.Id = item.Id;
        VehList.Fk_G_Customer_Id.Name = item.Acc_Titel;
        this.itemVm.Fk_G_Customer_Id.Id =item.Id;
        this.itemVm.Fk_G_Customer_Id.Name =item.Acc_Titel;
        this.customerAccount = new ItemViewModel(item.Id, item.Acc_Titel);
        this.vmP.Id = VehList.Fk_G_Customer_Id.Id;
        this.vmP.Acc_Titel = VehList.Fk_G_Customer_Id.Name;
        selectedCustomerL.push(VehList);
      }
      selectedCustomerL = selectedVehicle = this.selectedvehicleDetail;
      selectedCustomerL = this.selectedcustomerDetail;
      selectedVehicle = this.selectedvehicleDetail;
  }

  onDeSelectcustomerDetail(e) {
    if (this.selectedcustomerDetail.length == 0) {
      this.vmP = new DefineAccountviewModel();
      this.vmP.Acc_Titel = this.vmP.Acc_Titel;
      this.getRegNo();
    }
  }

  vatChanged() {
  
    if (this.vmDetail > -1) {
      if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
        this.vm.SaleDetails.forEach((x) => {
          x.VAT_Value = this.vmDetail;
          x.VatAmount = (x.TotalAmount / 100) * this.vmDetail;
          // x.VatAmount =  (x.VatAmount.toFixed(2);
        });
      }
      if (this.vmp.SpareParts !== undefined && this.vmp.SpareParts.length > 0) {
        this.vmp.SpareParts.forEach((x) => {
          x.VatAmount = (x.Amount / 100) * this.vmDetail;
          x.TotalAmount = x.Amount + x.VatAmount;
          // x.VatAmount.toPrecision(2);
          // x.VatAmount= x.VatAmount.toFixed(2);
          console.log(x.VatAmount);
        });
      }

      if (this.vmp.Services !== undefined && this.vmp.Services.length > 0) {
        this.vmp.Services.forEach((x) => {
          x.VatAmount = (x.Amount / 100) * this.vmDetail;
          x.TotalAmount = x.Amount + x.VatAmount;
          // x.VatAmount =  x.VatAmount.toFixed(2);
          // x.VatAmount.toFixed(2);
        });
      }
      this.calculateTotals();
    }
  }

  public TotalPayable = 0;
  public TotalVat: number;
  public TotalPayableAfterVat = 0;
  calculateTotals() {
    this.TotalPayableAfterVat = 0;
    this.TotalVat = 0;
    this.TotalPayable = 0;
    this.vmp.DiscountAmount = 0;
    this.vmp.PartsAmount = 0;
    if (this.vm.SaleDetails !== undefined && this.vm.SaleDetails.length > 0) {
      this.vmp.SpareParts.forEach((x) => {
        this.vmp.PartsAmount += x.Amount;
        this.TotalVat += x.VatAmount;
        if (x.DescountAmmount > 0) {
          this.vmp.DiscountAmount += x.DescountAmmount * x.Quantity;
        }
      });
      this.vmp.ServiceAmount = 0;
      this.vmp.Services.forEach((x) => {
        x.VatAmount = x.VatAmount;
        this.vmp.ServiceAmount += x.Amount;
        this.TotalVat += x.VatAmount;
        if (x.DescountAmmount > 0) {
          this.vmp.DiscountAmount += x.DescountAmmount * x.Quantity;
        }
      });
    }
    this.vmp.PackageAmount =
      this.vmp.PartsAmount + this.TotalVat + this.vmp.ServiceAmount;
      // if(this.vm.PaymentType === 3){
        this.totalExpense=  this.vmp.PackageAmount;
        this.allowedExpense = this.vmp.PackageAmount;
        this.vm.AllowedExpense =this.allowedExpense;
        this.vm.TotalExpense =this.totalExpense;
      // }
      
  }

  onSelectWareHouse() {
    console.log(this.vm.Fk_G_WareHouse_Id);
    this.getSpareParts(this.selectedvehicleDetail[0].Id, this.vm.Fk_G_WareHouse_Id.Id);
  }

  getSpareParts(id, idd?) {
    
    let selectedSpare: any = [];
    this._uow.quotationService
      .GetSSItemDefiningByItemTypeByModel(id, idd)
      .subscribe(
        (res: any) => {
          
          this.spareParts = res;
          this.InventoryCheckAll();
          console.log("spare parts", res);
          if (this.selectedSpareParts.length > 0) {
            console.log(this.selectedSpareParts);
            this.selectedSpareParts.forEach((x) => {
              console.log(this.vmp.SpareParts);
              
              let selected = this.vmp.SpareParts.find((z) => z.Fk_G_SSItemDefining_Id.Id === x.Id);
              console.log("selcetd part", selected);
              let md = new ItemViewModel();
              md.Id = selected.Fk_G_SSItemDefining_Id.Id,
                md.Name = selected.Fk_G_SSItemDefining_Id.Name
              selectedSpare.push(md);
              this.selectedSpareParts = selectedSpare;
              // Edit 
             
            });
          }
          if(this.Id != null && this.Id != undefined && this.Id != 0){
            this.getQuotationById(this.Id);
            }
        },
        (err) => {
          this._uow.logger.log(err);
          this.spareParts = [];
        }
      );

      
  }

  getTags() {
    this._uow.tagsService.GetList().subscribe(
      (res: any) => {
        this.tags = res;
      },
      (err) => console.log(err)
    );
  }

  InventoryCheckAll() {
    
    if (this.vmp.SpareParts != undefined && this.vmp.SpareParts.length > 0) {
      this.vmp.SpareParts.forEach((x) => {
        let SparepartsEvent = this.spareParts.find(
          (y) => y.Id === x.Fk_G_SSItemDefining_Id.Id
        );
        if (SparepartsEvent != undefined && SparepartsEvent != null) {
          if (x.Quantity > SparepartsEvent.CurrentStock) {
            x.IsInventry = false;
            //this.DisableSave = true;
          } else {
            x.IsInventry = true;
            //this.DisableSave = false;
          }
        }
      });
      let DisableSave = this.vmp.SpareParts.find(x=> x.IsInventry === false);
      if (DisableSave) {
        this.DisableSave = true;
      }else{
        this.DisableSave = false;
      }
    } else {
      this.DisableSave = false;
    }
  }

  InventoryCheck(e) {
    
    let SparepartsEvent = this.spareParts.find((y) => y.Id === e);
    if (SparepartsEvent != undefined && SparepartsEvent != null) {
      let AlreadySelectedParts = this.vmp.SpareParts.find(
        (y) => y.Fk_G_SSItemDefining_Id.Id === e
      );
      if (AlreadySelectedParts != undefined && AlreadySelectedParts != null) {
        let AlreadyPartsIndex = this.vmp.SpareParts.indexOf(
          AlreadySelectedParts
        );
        if (AlreadySelectedParts.Quantity > SparepartsEvent.CurrentStock) {
          if (AlreadyPartsIndex != undefined) {
            this.vmp.SpareParts[AlreadyPartsIndex].IsInventry = false;
          }
          //this.DisableSave = true;
        } else {
          if (AlreadyPartsIndex != undefined) {
            this.vmp.SpareParts[AlreadyPartsIndex].IsInventry = true;
          }
          AlreadySelectedParts.IsInventry = true;
          //this.DisableSave = false;
        }
      }
    }
    this.InventoryCheckAll();
  }

  getUserAssingnedWareHouses() {
    return new Promise((resolve, reject) => {
      this._uow.warehouseService.GetListByUserId().subscribe((res: any) => {
        console.log("warehouses", res);
        
        this.userAssignedWareHouses = res;
        resolve(res);
      });
    });
  }

  onSelectSparePart(e) {
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    this._uow.logger.log(e);
    let item = this.spareParts.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.DiscountedRate = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      i.Discount = 0;
      i.DiscountAmount = 0;
      this.vm.SaleDetails.push(i);
      let a = new GPackageItemViewModel();
      a.Id = 0;
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      a.DiscountAmount = item.SalePrice;
      if (this.itemVm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
      }
      this.vmp.SpareParts.push(a);
      this.discountChange(this.vmp.SpareParts[this.vmp.SpareParts.indexOf(a)]);
      //this.selectedSpareParts.push(a);
    }
    this.vatChanged();
    this.calculateTotals();
    this.InventoryCheck(e.Id);
  }

  onDeSelectSparePart(e) {
    
    let i = this.vmp.SpareParts.find(
      (x) => x.Fk_G_SSItemDefining_Id.Id === e.Id
    );
    if (i !== undefined) {
      this.vmp.SpareParts.splice(this.vmp.SpareParts.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find((x) => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    // let a = this.vm.SpareParts.find(x=> x.FK_G_Item_Id.Id === e.Id);
    //   if(a !== undefined){
    //     this.vm.SpareParts.splice(this.vm.SpareParts.indexOf(a),1);
    //   }

    this.calculateTotals();
    this.InventoryCheckAll();
  }

  discountChange(e, i?) {
    debugger
    let discountAMmount = (e.DescountPercentage * e.SalePrice) / 100;
    e.DescountAmmount = discountAMmount;
    e.DiscountAmount = e.SalePrice - e.DescountAmmount;
    e.Amount = e.DiscountAmount * e.Quantity;
    let saledtl = this.vm.SaleDetails.find(
      (x) => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (saledtl != undefined) {
      saledtl.Rate = e.SalePrice;
      saledtl.DiscountAmount = e.DescountAmmount;
      saledtl.Discount = e.DescountPercentage;
      saledtl.DiscountedRate = e.DiscountAmount;
      saledtl.TotalAmount = e.Amount;
    }
   
    this.vatChanged();
    //this.calculateTotals();
  }

  openModalLg(template: TemplateRef<any>, itemtype?) {
    this.ssItemVm = new GItemDefiningViewModel();
    if (itemtype == 1) {
      let i = this.itemTypes.find((x) => x.Name == "Spare-Parts");
      console.log(i);
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    if (itemtype == 2) {
      let i = this.itemTypes.find((x) => x.Name == "Services");
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    this.openModal(template);
    this.modalRef.setClass("modal-lg");
  }

  onSelectService(e) {
    
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    if (
      this.SelectedItemServices == undefined ||
      this.SelectedItemServices.length == 0
    ) {
      this.SelectedItemServices = [];
    }
    this._uow.logger.log(e);
    let item = this.services.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.DiscountedRate = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      i.Discount = 0;
      i.DiscountAmount = 0;
      this.vm.SaleDetails.push(i);
      let a = new GPackageItemViewModel();
      a.Id = 0;
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      a.DiscountAmount = item.SalePrice;
      debugger
      if (this.itemVm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
      }
      this.vmp.Services.push(a);
      this.discountChange(this.vmp.Services[this.vmp.Services.indexOf(a)]);
      this.SelectedItemServices.push(item);
    }
    this.vatChanged();
    this.calculateTotals();
  }

  onDeSelectService(e) {
    let i = this.vmp.Services.find((x) => x.Fk_G_SSItemDefining_Id.Id === e.Id);
    if (i !== undefined) {
      this.vmp.Services.splice(this.vmp.Services.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find((x) => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    this.calculateTotals();
  }

  getServices() {
    // 'Services'
    this._jq.showSpinner(true);
    this._uow.quotationService.GetSSItemDefiningByItemType("Services").subscribe(
      (res: any) => {
        this.services = res;
        
        console.log(res);
        this._jq.showSpinner();
        // this._uow.logger.log(`services ${res}`);
      },
      (err) => {
        this._uow.logger.log(err);
        this.services = [];
        this._jq.showSpinner();
      }
    );
  }

  quantityChange(e, i?) {
    //e.DiscountAmount = 0;
    e.Amount = e.Quantity * e.DiscountAmount;
    let saledetail = this.vm.SaleDetails.find(
      (x) => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id
    );
    if (saledetail != undefined) {
      saledetail.Quantity = e.Quantity;
      saledetail.TotalAmount = e.Amount;
    }
    //  if (e.DescountPercentage > 0) {
    //    e.Amount = (e.Amount - ((e.DescountPercentage / 100) * e.Amount));
    //  }
    //this.calculateTotals();
    this.vatChanged();
    this.InventoryCheckAll();
  }

  amountChange(e, i?) {
    e.DescountPercentage = (e.DescountAmmount * 100) / e.SalePrice;
    this.discountChange(e, i);
    this.vatChanged();
    
  }
  vatAmountChanged(i) {
    this.calculateTotals();
  }

  onSelectTags(e) {
    if (
      this.vm.SalesTagsList == undefined ||
      this.vm.SalesTagsList.length == 0
    ) {
      this.vm.SalesTagsList = [];
    }
    this._uow.logger.log(e);
    let item = this.tags.find((x) => x.Id === e.Id);
    if (item !== undefined) {
      let i = new G_TagsViewModel();
      i.Name = item.Name;
      i.FK_Tag_Id = item.Id;
      this.vm.SalesTagsList.push(i);
      // let a = new G_SaleMasterViewModel();
      // a.Id = item.Id;
      // a.Name = item.Name;
      // this.vm.SalesTags.push(i);
    }
  }

  onDeSelectTags(e) {
    let i = this.vm.SalesTagsList.find((x) => x.FK_Tag_Id === e.Id);
    if (i !== undefined) {
      this.vm.SalesTagsList.splice(this.vm.SalesTagsList.indexOf(i), 1);
    }
  }

  onSelect(e) {
    

    this.files.push(...e.addedFiles);

    this.vm.file = this.files;
  }
  onRemove(e) {
    console.log(e);
    this.files.splice(this.files.indexOf(e), 1);
  }

  onItemSubmitted(e) {
    if (e == true) {
      this.getList();
      this.getTags();
      this._jq.showSpinner(false);
    }
  }
  getList() {
    this._jq.showSpinner(true);
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        this.vmListItems = res.DtlList;
        console.log(res);
        this._jq.showSpinner();
      },
      (err) => {
        console.log(err);
        this._jq.showSpinner();
      }
    );
  }

  private serialCounter: number = 1000000;
generateUniqueRandomString(): string {
  this.serialCounter = 1000000;
  let newSerialNumber: string;

  // Loop until a unique string is generated
  do {
    newSerialNumber = this.generateRandomString();
  } while (this.quoteNumberList.includes(newSerialNumber)); // Check if the string already exists in requestCodes

  return newSerialNumber;
}
generateRandomString(): string {
  const currentSerialNumber = this.serialCounter++;
  
  // Use the custom padStart function to ensure it's always 7 digits long
  return 'QT-' + this.padStart(currentSerialNumber.toString(), 7, "0");
}

padStart(str: string, targetLength: number, padString: string): string {
  while (str.length < targetLength) {
    str = padString + str;
  }
  return str;
}

getQuotationById(Id){
  this._uow.quotationService.GetById(Id).subscribe(
    (res: any) => {
      
      var data = res;
      let selectedSaprePartsList : any = [];
      let selectedServicesList : any = [];
      let selectedTagLists: any = [];
      let selectedVehicle: any = [];
      let selectedCustomer: any = [];
      
      this.Id = data.Id;
      this.quotationNumber = data.QuoteNumber;
      this.quotationSubject = data.Subject;
      this.vm.TransactionDate = new Date(data.QuoteDate);
      this.noteText = data.Note;
      debugger
      const vehicle = this.vehicleDetail.find(v => v.RegistraionNumber === data.FK_Vehicle.Name);
      this.vehicleDetailObj = {
        Id: vehicle.Id, // Get the found vehicle's Id
        RegistraionNumber: vehicle.RegistraionNumber,
        Fk_G_Customer_Id: {
            Id: vehicle.Fk_G_Customer_Id.Id,
            Name: vehicle.Fk_G_Customer_Id.Name
        },
        DriverPhone: data.DriverPhone,
    };


      let veh = new GVechileViewModel();
      veh.Id = vehicle.Id;
      veh.RegistraionNumber = vehicle.RegistraionNumber;
      veh.RegistraionNumber = data.VehicleNumber;
      veh.Odometer = parseInt(data.Odometer);
      veh.DriverName= data.DriverName;
      veh.DriverPhone = data.DriverPhone;
      veh.Fk_G_Customer_Id = {
        Id: 0,  // Initialize Id if available
        Name: data.FK_Customer.Name || '' // Initialize Name if available
    },
      selectedVehicle.push(veh);
      this.selectedvehicleDetail = selectedVehicle;

      if (data.Vat != undefined ) {
        this.vmDetail = parseFloat(data.Vat);
      }

      let cust = new DefineAccountviewModel();
      cust.Id = vehicle.Fk_G_Customer_Id.Id,
      cust.Acc_Titel = vehicle.Fk_G_Customer_Id.Name
      selectedCustomer.push(cust);
      this.selectedcustomerDetail = selectedCustomer;

    

      this.itemVm = {
        RegistraionNumber: data.VehicleNumber || '',
        Odometer: parseInt(data.Odometer) || 0,
        DriverName: data.DriverName || '',
        DriverPhone: data.DriverPhone || '',
        Fk_G_Customer_Discount: vehicle.Fk_G_Customer_Discount,
        Fk_G_Customer_Id: {
          Id: 0,  // Initialize Id if available
          Name: data.FK_Customer.Name || '' // Initialize Name if available
      },
        // Initialize other properties as needed...
      } as GVechileViewModel;
      
      const warehouse = this.userAssignedWareHouses.find(w => w.FK_WareHouse_Id.Name === data.FK_Werahouse.Name);
      if (warehouse) {
        this.vm.Fk_G_WareHouse_Id = warehouse.FK_WareHouse_Id; // Directly assign the object reference
      }
      
      
      ////////////////
      data.GQuotationDetail.forEach(element => {
        if (element.Type === "Service") {  // Filter for "Service" type
          let item = this.services.find((x) => x.Name === element.ItemName);
          if (item !== undefined) {
            let i = new SaleDetailViewModel();
            i.Id = item.Id;
            i.Quantity = 1;
            i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
            
            let a = new GPackageItemViewModel();
            a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
            a.Quantity= element.Quantity,
            a.SalePrice= element.Rate,
            a.VatAmount= element.VatAmount,
            a.DiscountAmount = element.DiscountRate,
            a.DescountPercentage= element.DiscountPercantage,
            a.DescountAmmount= element.DiscountAmount,
            a.Amount= element.TotalAmount,
            a.TotalAmount = element.TotalAmountWithVat
            this.vmp.Services.push(a);
           
            this.SelectedItemServices.push(item);
            //this.discountChange(a, 0);
          }
        }
        if (element.Type === "Part") {  // Filter for "Service" type
          let item = this.spareParts.find((x) => x.Name === element.ItemName);
          if (item !== undefined) {
            let i = new SaleDetailViewModel();
            i.Id = 0;
            i.Quantity = 1;
            i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
            
            let a = new GPackageItemViewModel();
            a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
            a.Quantity= element.Quantity,
            a.SalePrice= element.Rate,
            a.VatAmount= element.VatAmount,
            a.DiscountAmount = element.DiscountRate,
            a.DescountPercentage= element.DiscountPercantage,
            a.DescountAmmount= element.DiscountAmount,
            a.Amount= element.TotalAmount,
            a.TotalAmount = element.TotalAmountWithVat
            this.vmp.SpareParts.push(a);
            
            this.selectedSpareParts.push(item);
            //this.discountChange(a, 0);
          }
        }
      });
     
debugger

      /////////////
      this.vmp = {
        Id: data.Id || null, // or another appropriate default value
        Name: data.Name || '', // populate based on your data structure
        Description: data.Description || '',
        Fk_PackageType_Id: data.Fk_PackageType_Id || null, // populate based on your data structure
        SpareParts: this.vmp.SpareParts
          .map(detail => ({
            Fk_G_SSItemDefining_Id: detail.Fk_G_SSItemDefining_Id,
            Quantity: detail.Quantity,
            SalePrice: detail.SalePrice,
            VatAmount: detail.VatAmount,
            Amount: detail.Amount,
            DescountPercentage: detail.DescountPercentage,
            DescountAmmount: detail.DescountAmmount,
            TotalAmount: detail.TotalAmount,
            DiscountAmount: detail.DiscountAmount
          })),
        
        Services: this.vmp.Services
          .map(detail => ({
            Fk_G_SSItemDefining_Id: detail.Fk_G_SSItemDefining_Id,
            Quantity: detail.Quantity,
            SalePrice: detail.SalePrice,
            VatAmount: detail.VatAmount,
            Amount: detail.Amount,
            DescountPercentage: detail.DescountPercentage,
            DescountAmmount: detail.DescountAmmount,
            TotalAmount: detail.TotalAmount,
            DiscountAmount: detail.DiscountAmount
          })),
        
        // Populate any additional required properties here
        ServiceAmount: data.ServiceTotal || 0, // Ensure this is included
        PartsAmount: data.PartsTotal || 0, // Ensure this is included
        DiscountAmount: data.TotalDiscount || 0, // Ensure this is included
        
        PackageAmount: data.Total || 0 // Ensure this is included
      } as GPackagesViewModel;
      this.TotalVat = data.TotalVat;

      this.vm.SalesTagsList = this.vm.SalesTagsList || [];
      data.GQuotationTags.forEach(tag => {
        // Step 2: Create a new G_TagsViewModel instance
        let tg = this.tags.find((x) => x.Name === tag.Tag);
        const selectedTag = new G_TagsViewModel();
        selectedTag.Id = tg.Id;
        selectedTag.FK_Tag_Id = tag.FK_Tag_Id;
        selectedTag.Name = tag.Tag; // Assign the tag name directly from the data
        
        // Step 3: Push the selected tag into the selectedTagLists array
        selectedTagLists.push(selectedTag);
        this.vm.SalesTagsList.push(selectedTag);
    });
    
    // Step 4: Assign the selectedTagLists to this.selectedTagsList for display
    this.selectedTagsList = selectedTagLists;

      if (this.itemVm.FK_Tag_List !== undefined && this.itemVm.FK_Tag_List.length > 0) {
        this.selectedTagList = this.itemVm.FK_Tag_List.map(tag => 
            new ItemViewModel(tag.Id, tag.Name)
        );
    }

      // Set other totals and file
      this.TotalVat = data.TotalVat;
      this.vm.file = data.file;

      console.log(res);
      this._jq.showSpinner();
    },
    (err) => {
      console.log(err);
      this._jq.showSpinner();
    }
  );
}
  OnSubmit(){
    
    //this.spinner.show();
    this.quotationSave.Id = this.Id;
    this.quotationSave.FK_Vehicle = new ItemViewModel(this.vehicleDetailObj.Id, this.vehicleDetailObj.RegistraionNumber);
    this.quotationSave.FK_Customer = new ItemViewModel(this.vehicleDetailObj.Fk_G_Customer_Id.Id, this.vehicleDetailObj.Fk_G_Customer_Id.Name);
    this.quotationSave.VehicleNumber = this.vehicleDetailObj.RegistraionNumber;
    this.quotationSave.Odometer = this.itemVm.Odometer.toString();
    this.quotationSave.DriverName = this.itemVm.DriverName;
    this.quotationSave.DriverPhone = this.vehicleDetailObj.DriverPhone;
    this.quotationSave.QuoteDate = new Date(this.vm.TransactionDate);
    this.quotationSave.Subject = this.quotationSubject;
    this.quotationSave.QuoteNumber = this.quotationNumber;
    this.quotationSave.CustomerCode = this.vehicleDetailObj.Fk_G_Customer_Id.Name;
    this.quotationSave.Vat = this.vmDetail;
    this.quotationSave.FK_Werahouse = new ItemViewModel(this.vm.Fk_G_WareHouse_Id.Id, this.vm.Fk_G_WareHouse_Id.Name);
    this.quotationSave.Note = this.noteText;
debugger
    this.quotationSave.GQuotationDetail = [
      ...this.vmp.SpareParts.map(part => ({
          ItemName: part.Fk_G_SSItemDefining_Id.Name,  
          Type: "Part",
          Quantity: part.Quantity,
          Rate: part.SalePrice,   
          VatAmount: part.VatAmount,
          DiscountRate: part.Amount,
          DiscountPercantage: part.DescountPercentage,
          DiscountAmount: part.DescountAmmount,
          TotalAmount: part.DiscountAmount,
          TotalAmountWithVat: part.TotalAmount
      } as GQuotationDetail)),
    
      ...this.vmp.Services.map(part => ({
          ItemName: part.Fk_G_SSItemDefining_Id.Name,  
          Type: "Service",
          Quantity: part.Quantity,
          Rate: part.SalePrice,   
          VatAmount: part.VatAmount,
          DiscountRate: part.Amount,
          DiscountPercantage: part.DescountPercentage,
          DiscountAmount: part.DescountAmmount,
          TotalAmount: part.DiscountAmount,
          TotalAmountWithVat: part.TotalAmount
      } as GQuotationDetail))
    ];

  this.quotationSave.GQuotationTags = this.vm.SalesTagsList.map(part => {
    return {
      // Map the necessary fields from SpareParts to GQuotationDetail
      Tag: part.Name, 
    } as GQuotationTags;
  });
  this.quotationSave.file = this.vm.file;
  this.quotationSave.ServiceTotal = this.vmp.ServiceAmount;
  this.quotationSave.PartsTotal = this.vmp.PartsAmount;
  this.quotationSave.TotalDiscount = this.vmp.DiscountAmount;
  this.quotationSave.TotalVat = this.TotalVat;
  this.quotationSave.Total = this.vmp.PackageAmount;

  this._uow.quotationService
  .Post(this.quotationSave)
  .toPromise()
  .then((res: any) => {
    console.log(res);
    
    this.submitted = true;
    this.onSubmitted.emit(this.submitted);
    //this.bsmRef.hide();
    this.vm = res;
    this.EnableNext = true;
    this.spinner.hide();
    Swal.fire("Saved!", "Quotation Created!", "success");
    // this._jq.showSpinner();
    this._router.navigate(["/quotation"]);
  })
  .catch((error) => {
    Swal.fire("Error!", "Quotation Not Saved!", "error");
    this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
    this.onSubmitted.emit(false);
    // this._jq.showSpinner();
    this.spinner.hide();
  });

  }

  
}
