import { Component, OnInit, TemplateRef } from '@angular/core';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ExportExcel } from 'src/app/viewmodels/ExportExcel';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';

@Component({
  selector: 'app-sale-completed',
  templateUrl: './sale-completed.component.html',
  styleUrls: ['./sale-completed.component.scss']
})
export class SaleCompletedComponent implements OnInit {
  myDate = new Date(Date.now());
  public component_Title = 'WIP Repair Orders';
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public vmListItems: any = [];
  public attachmentList: any = [];
  public vmCompList : any = [];
  modalRef: BsModalRef;
  p: number = 1;
  public customerDetail: any = null;
  public selectedcustomerDetail: any = null;
  public exportFormat: string = 'csv';
  startDate: any = null;
  endDate : any = null;
  public CustomerDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',  // The field to use for the selected ID
    textField: 'name',  // The field to use for displaying the customer name
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
  };
  public client: any;
  constructor(
    private datePipe: DatePipe,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.spinner.show();
    this._uow.saleService.GetPagedResultByCompleted().subscribe((res: any) => {
      this.vmCompList = res.DtlList;
      this.vm = res.DtlList;

      console.log(this.vm);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
      // this._jq.showSpinner();
    });
  }

  onButtonClick() {
    debugger
    this._jq.showSpinner(true);
    this._router.navigate(['/repair-invoice', {Id: this.vm.Id}]);
  }
  openAttachmentModal(template: TemplateRef<any>,b) {
    debugger
    this._uow.saleService.GetById(b).subscribe((res: any)=>{
      this.attachmentList = res.AttachmentList;
      console.log(this.attachmentList);
    });
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }
  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.saleService.GetPagedSearchByCompleted(this.searchTerm).subscribe((res: any) => {
        this.vmCompList = res.DtlList;
        console.log(this.vmCompList);
        // this._jq.showSpinner();
        this.spinner.hide();
      },  (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    }
  }

  // extrnal components
  onItemSubmitted(e) {
    console.log(e);
    if (e === true) {
      this.vm = new G_SaleMasterViewModel();
      this.getList();
    } else {
      this._jq.showSpinner();
    }
  }

  getList() {
    this._uow.saleService.GetPagedResultByCompleted().subscribe((res: any) => {
      this.vmListItems = res;
      this.vmCompList = res.DtlList;
      this.vm = res.DtlList;
      console.log(res);
      this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      this._jq.showSpinner();
    });
  }

  conformDelete() {
    this.spinner.show();
    this._uow.saleService.Delete(this.vm).subscribe((res: any) => {
      this.getList();
      this.modalRef.hide();
      this.spinner.hide();
    }, (err) => {
      // this._jq.showSpinner();
      this.spinner.hide();
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.vm = model;
    } else {
      this.vm = new G_SaleMasterViewModel();
    }
  }
  openExportModel(template: TemplateRef<any>) {
    this.openModal(template);
    this.getCustomerName();
  }

  getCustomerName() {
    debugger;
    this._uow.customerService.GetList().subscribe(
      (res: any) => {
        debugger;
        this.customerDetail = res.DtlList.map(x => {
          return {
            id: x.Id, // or the actual ID field
            name: x.Acc_Code + '-' + x.Acc_Titel // concatenated name and code
          };
        });
       
        // this.itemVm.RegistraionNumber = res;
        console.log(res);
        this._jq.showSpinner();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this._jq.showSpinner();
      }
    );
  }
  exportToExcel(){
debugger;
this.spinner.show();
    var obj = new ExportExcel();
    obj={
      Customer_Id: this.selectedcustomerDetail && this.selectedcustomerDetail.length > 0 
      ? this.selectedcustomerDetail[0].id 
      : null,  // Assign null if selectedcustomerDetail is null or empty
      StartDate: this.startDate,
      EndDate: this.endDate,
      Format: this.exportFormat
    }
this._uow.saleService.ExportSaleCompleted(obj).subscribe((res:any)=>{
debugger;

const blob = new Blob([res], { type: res.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `CompletedSalesData_${new Date().toISOString().split('T')[0]}.${this.exportFormat.toLowerCase()}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        this.spinner.hide();
        this.modalRef.hide();
},
(error)=>{
  this._uow.logger.log(error);
  this.spinner.hide();
  Swal.fire(
    'Failure!',
    'Network Issue!',
    'error'
  )
});
  }
}
