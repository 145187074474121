import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';
import { GQuotationSaveModel } from 'src/app/viewmodels/g-quotation/QuotationModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {

  public SortBy:string = "asc";
  public modalRef: BsModalRef;
  public itemVm: GQuotationSaveModel;
  public attachmentList: any = [];
  public vmListItems: GQuotationSaveModel[] = [];
  p: number = 1;
  dataTable: any;
  @Input('dataTable') table;
  dtOption: any = {};
  public client: any;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.getList();
    this.dtOption = {
      "paging":   false,
      "searching": false,
      "info": false,
  };
  $(() =>{
    $('table.display').DataTable(this.dtOption);
  })
  }


  getList() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.quotationService.GetList().subscribe((res: any) => {
      this._jq.reinitDataTable(res);
      debugger
      this.vmListItems = res.DtlList;
      console.log(res);

       this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
    this._jq.showSpinner();
      this.spinner.hide();
  }
  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.quotationService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.p = 1;
        this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    } else {
      this.getList();
    }
  }

  getSortIndexList(sIndex) {
    debugger
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.SortBy = this.SortBy == "asc" ? "desc" : "asc";
    this._uow.quotationService.GetPagedResult(0, 50, null, sIndex, this.SortBy).subscribe((res: any) => {
      debugger
      this._jq.reinitDataTable(res);
      this.vmListItems = res.DtlList;;
      console.log(res);
      this.spinner.hide();
      // this._jq.showSpinner();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

  EditQuotation(e) {
    this.router.navigate(['/edit-quotation', e]);
  }

  // extrnal components
  onItemSubmitted(e) {
    document.location.reload();
    console.log(e);
    if (e === true) {
      //this.itemVm = new G_PurchaseMasterViewModel();
      this.getList();
    }
  }

  openDeleteModel(template: TemplateRef<any>, model?) {
    this.openModal(template);
    if (model !== undefined) {
      this.itemVm = model;
    }
  }

  conformDelete() {
    this.spinner.show();
     this._uow.quotationService.Delete(this.itemVm).subscribe((res: any) => {
       this.getList();
       this.modalRef.hide();
       this.spinner.hide();
     }, (err) => {
        this._jq.showSpinner();
       this.spinner.hide();
     });
  }

  openAttachmentModal(template: TemplateRef<any>,b) {
    debugger
    this.attachmentList = this.vmListItems.reduce((acc, item) => {
      const attachments = item.GQuotationAttachments.map(att => att.Attachment);
      return acc.concat(attachments);
    }, []);
    this.modalRef = this._uow.modalServiceEx.show(template);
    this.modalRef.setClass('modal-lg');
  }

}
