import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { VmhReportModel, VMHReportVM } from 'src/app/viewmodels/VMHReportModel';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';
@Component({
  selector: 'app-vmh-report',
  templateUrl: './vmh-report.component.html',
  styleUrls: ['./vmh-report.component.scss']
})
export class VmhReportComponent implements OnInit {

  fromDate: any = null;
  toDate : any = null;

  public vehicleDetail: any = [];
  public cvehicleDetail = "vehicleDetail";
  public selectedvehicleDetail: any = [];
  public VmhReportVm: VMHReportVM = new VMHReportVM();
  public VmhReportVmCount: number;
  public name: string = "hello";
  

  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "Id",
    textField: "RegistraionNumber",
    enableCheckAll: false,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  public client: any;
  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.getRegNo();
  }

  getVMHReport(){
    debugger
    this.spinner.show();

    var obj = new VmhReportModel();
        obj={
          Id: this.selectedvehicleDetail && this.selectedvehicleDetail.length > 0 
          ? this.selectedvehicleDetail[0].Id 
          : null,  // Assign null if selectedcustomerDetail is null or empty
          FromDate: this.fromDate,
          ToDate: this.toDate,
        }

        this.VmhReportVm = new VMHReportVM();
    // let selectedC = this.customerData;

    this._uow.vehicleService.GetVmhReport(obj).subscribe((res: any) => {
      try {
          debugger;
          console.log(res); // Log the entire response to inspect its structure
          this.spinner.hide();
          // If res["DtlList"] is undefined or empty, handle it appropriately
          if (res && res["DtlList"] && res["DtlList"].length > 0) {
              this.VmhReportVm = res["DtlList"][0];
              this.VmhReportVmCount = res["TotalCount"];
              
              
          } else {
              console.warn('DtlList is empty or undefined in the response.');
              this.VmhReportVmCount =0;
          }
          
          
      } catch (error) {
          console.error('Error processing response:', error);
          this.spinner.hide();
      }
  }, (err) => {
      console.error('API call error:', err);
      this.spinner.hide();
  });
  this.spinner.hide();
  }
  getRegNo() {
    this.spinner.show();
    this._uow.vehicleService.GetList().subscribe(
      (res: any) => {
        debugger
        this.vehicleDetail = res;
        // this.itemVm.RegistraionNumber = res;
        console.log(res);
        this.spinner.hide();
        // this.itemVm.RegistraionNumber = res.code;
      },
      (error) => {
        this._uow.logger.log(error);
        this.spinner.hide();
      }
    );
  }

  exportToPdf() {
    this.spinner.show();
    debugger;
    const element = document.getElementById('VmhReport');

    html2canvas(element, {
      scale: 2,
      useCORS: true,
      allowTaint: true
  }).then((canvas) => {
      canvas.toBlob((blob) => {
          const imgData = URL.createObjectURL(blob);
          const pdf = new jsPDF('p', 'mm', 'a4');
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;
          
          const ratio = pdfWidth / canvasWidth;
          const pdfCanvasHeight = canvasHeight * ratio;
          
          let position = 0;
          let pageHeight = pdfHeight;
          let heightLeft = pdfCanvasHeight;
          
          while (heightLeft > 0) {
              if (position !== 0) {
                  pdf.addPage();
              }
  
              pdf.addImage(
                  imgData,
                  'PNG',
                  0,
                  position - (position > 0 ? 5 : 0),
                  pdfWidth,
                  pdfCanvasHeight
              );
              heightLeft -= pageHeight;
              position -= pageHeight;
          }
          
          var filename = `VMHReport_${new Date().toISOString().split('T')[0]}.pdf`
          pdf.save(filename);
      }, 'image/jpeg');
  });
    this.spinner.hide();
}
  

}
