import { Injectable } from '@angular/core';
import { IHttpService } from '../../common/IHttpService';
import { GQuotationSaveModel } from 'src/app/viewmodels/g-quotation/QuotationModel';
import { EndpointConfig } from '../../common/EndpointConfig';
import { HttpClient } from '@angular/common/http';
import { DefaultService } from '../../common/default.service';
import { Paginator } from 'src/app/viewmodels/paginator';
import { Raw } from 'src/app/viewmodels/Raw';

@Injectable({
  providedIn: 'root'
})
export class GQuotationService  implements IHttpService<GQuotationSaveModel> {

  private _ep: EndpointConfig;
  constructor(private _client: HttpClient, private _defaultService: DefaultService) {
    this._ep = new EndpointConfig('GQuotation');
  }
  GetList() {
    var pg = new Paginator(0, 50, null, 0);
    return this._client.post(this._ep.getEndpointMethod('GetG_GQuotationList'), pg);
  }

  GetPagedBySearch(searchTerm) {
    let pager = new Paginator(0, 50, null, 0);
    pager.Search = searchTerm;
    return this._client.post(this._ep.getEndpointMethod('GetG_GQuotationList'), pager);
  }
  GetPagedResult(start = 0, pagesize = 20, flag = '', sortIndex, SortBy) {
    let pg = new Paginator(start, pagesize, flag, sortIndex, SortBy);
    pg.Search = null;
    pg.SortBy = SortBy;
    return this._client.post(this._ep.getEndpointMethod('GetG_GQuotationList'), pg);
  }
  GetById(id: any) {
    return this._client.post(this._ep.getEndpointMethod('GetG_GQuotationById'), { Id: id });
  }
  Post(obj: GQuotationSaveModel) {
    console.log(obj);
    debugger
    this._defaultService.dateTransFuckDatesInObject(obj);
    //obj.Fk_G_StatusType_Id = new ItemViewModel(E_G_EPSStatusType.SaleQout);

    let fd = new FormData();
    debugger
    if(obj.file!=undefined){
      for (var i = 0; i < obj.file.length; i++) {
      fd.append('file', obj.file[i]);
      }
    }
    fd.append('Obj',JSON.stringify(obj));
    return this._client.post(this._ep.getEndpointMethod('SaveG_Quotation'), fd);
  }
  Put(obj: GQuotationSaveModel) {
    throw new Error('Method not implemented.');
  }
  Delete(obj: GQuotationSaveModel) {
    return this._client.post(this._ep.getEndpointMethod('DeleteG_Quotation'), obj);
  }
  GetQuotationNumbers() {
    debugger

    return this._client.post(this._ep.getEndpointMethod('GetG_QuotationNumbers'), { Code: '-1' });
  }
  GetSSItemDefiningByItemType(code,id?) {
    debugger;
    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefiningByItemType'), { Code: code,Id:id });
  }
  GetSSItemDefiningByItemTypeByModel(modelid: any, Idd?: any) {
    debugger
    return this._client.post(this._ep.getEndpointMethod('GetG_SSItemDefiningByTypeAndModelId'), { Id: modelid , Idd : Idd});
  }
  GetQuotationNotes() {
    debugger

    return this._client.post(this._ep.getEndpointMethod('GetG_QuotationNotes'), { Code: '-1' });
  }
}
