import { Component, OnInit, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { JqHelper } from 'src/app/common/jq-helper';
import { G_SaleMasterViewModel } from 'src/app/viewmodels/g-sale/G_SaleMasterViewModel';
import { ItemViewModel } from 'src/app/viewmodels/ItemViewModel';
import { GItemDefiningViewModel } from 'src/app/viewmodels/g-item/GItemDefiningViewModel';
import { GVechileViewModel } from 'src/app/viewmodels/g-vehicle/GVechileViewModel';
import { G_PurchaseMasterViewModel } from 'src/app/viewmodels/g-purchase/G_PurchaseMasterViewModel';
import { PurchaseDetailViewModel } from 'src/app/viewmodels/g-purchase/PurchaseDetailViewModel';
import { GPackagesViewModel } from 'src/app/viewmodels/g-packages/GPackagesViewModel';
import { GPackageItemViewModel } from 'src/app/viewmodels/g-packages/GPackageItemViewModel';
import { GPackagesModelViewModel } from 'src/app/viewmodels/g-packages/GPackagesModelViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { G_ComplainsSuggestionsViewModel } from 'src/app/viewmodels/g-settings/G_ComplainsSuggestionsViewModel';
import { DefineAccountviewModel } from 'src/app/viewmodels/g-customer/DefineAccountviewModel';
import { G_TagsViewModel } from 'src/app/viewmodels/g-settings/G_TagsViewModel';
import { SaleDetailViewModel } from 'src/app/viewmodels/g-sale/SaleDetailViewModel';
import { G_PackagesTypeViewModel } from 'src/app/viewmodels/g-packages/G_PackagesTypeViewModel';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';

@Component({
  selector: 'app-edit-repair-order',
  templateUrl: './edit-repair-order.component.html',
  styleUrls: ['./edit-repair-order.component.scss']
})
export class EditRepairOrderComponent implements OnInit {
  public maxSelection: number = 1;
  modalRef: BsModalRef;
  selectedFile = null;
  public vmListItems: any = [];
  private submitted = false;
  @Input() public vmC: G_ComplainsSuggestionsViewModel;
  @Input() public vmD: DefineAccountviewModel;
  @Input() public vmP: GPackagesViewModel;
  @Input() public modal_title: any;
  @Input() public bsmRef: BsModalRef;
  @Output() onSubmitted = new EventEmitter<any>();  public component_Title = 'Sale';
  public vehicleDetail: any = [];
  public packageSparePart: any = [];
  public complaintsType: any = [];
  public ccomplaintsType: 'complaintsType';
  public findingsType: any = [];
  public cfindingsType: 'findingsType';
  public packageTypes: any = [];
  public cvehicleDetail = 'vehicleDetail';
  public selectedvehicleDetail: any = [];
  public itemVm: GVechileViewModel = new GVechileViewModel();
  public sparePartVm: GItemDefiningViewModel = new GItemDefiningViewModel();
  public vm: G_SaleMasterViewModel = new G_SaleMasterViewModel();
  public saleDetailVM: SaleDetailViewModel = new SaleDetailViewModel();
  public services: any = [];
  public selectedServices: any = [];
  public SelectedItemServices: any = [];
  public SelectedItemSparePArts: any = [];
  public cpackageTypes = 'packageTypes';
  public items: any = [];
  public brands: any = [];
  public selectedBrands: any = [];
  public models: any = [];
  public selectedModels: any = [];
  public citems: any = 'items';
  public cbrands: any = 'brands';
  public cmodels: any = 'models';
  public spareParts: any = [];
  public selectedSpareParts: any = [];
  public selectedPacakgeTypes: any = [];
  public selectedComplaint: any = [];
  public selectedFindings: any = [];
  public selectedTagsList: any = [];
  public tags: any = [];
  public show:boolean = false;
  public buttonName:any = 'Show';
  public isPreview: boolean = false;
  public thumbnail: any;
  files: File[] = [];
  public date: Date;
  public ComName: any;
  public Address: any;
  public contect: any;

  public brandsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public modelsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };
  public itemsDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: true,
  };
  public PackageSelectionDropdown: IDropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    limitSelection: 1
  };

  public itemTypes = [];
  public ssItemVm = new GItemDefiningViewModel();
  public defaultDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'Id',
    textField: 'RegistraionNumber',
    enableCheckAll: false,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  name = 'CP_Email';
  id = 'Id';
  checked = true;
  disabled = false;
  label = 'Toggle On/Off';
  labelledby = 'Some Other Text';
  SelectedSpare: any[];
  //SelectedServices: any;
  public calingUrl: string;
  public urlId: number;
  public client: any;
  constructor(
    private route: ActivatedRoute,
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _router: Router,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService
  ) { }



  ngOnInit() {
    this.spinner.show();
    this.client = this._localStorageService.get("com");
    this.getimage();
    this.getSparePartsAndServices();
    this.getSpareParts();
    this.getItems();
    this.getItemtypes();
    this.getComplaints();
    this.getPackageTypes();
    this.getFindings();
    this.getBrands();
    this.getTags();
    let date = new Date();
    document.getElementById("date").innerHTML = date.toLocaleDateString();
    this.vm = new G_SaleMasterViewModel();
    this.calingUrl = this._router.url;
    this.route.queryParams.subscribe(params => {
      this.urlId = params['id'];
    });
    // this._jq.showSpinner(true);
    this.spinner.show();
    debugger
    this._uow.saleService.GetById(this.urlId).subscribe((res: any)=>{
      this.vm = res;
      console.log(res);
      // this._jq.showSpinner();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 3000);
      let selectedServicesList : any = [];
      let selectedSaprePartsList : any = [];
      let selectedComplaintList: any = [];
      let selectedFindingsList: any = [];
      let selectedTagLists: any = [];
      this.vm.SaleDetails.forEach(x => {
        if (x.FK_G_Item_Type_Id.Id === 18) {

          let ab =  new GPackageItemViewModel();
          ab.Quantity = x.Quantity;
          ab.Fk_G_SSItemDefining_Id = new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name);
          ab.DescountPercentage = 0;
          ab.DescountPercentage = x.Discount;
          ab.SalePrice = 0;
          ab.SalePrice = x.SalePrice;
          ab.Amount = 0;
          ab.Amount = x.TotalAmount;
          ab.DiscountAmount =  0;
          ab.DiscountAmount = x.DiscountAmount;
          ab.DescountAmmount = 0;
          ab.DescountAmmount = x.DiscountedRate;
          this.vmP.SpareParts.push(ab);
          let partssSelected = new GItemDefiningViewModel();
          partssSelected.Id = x.FK_G_Item_Id.Id;
          partssSelected.Name = x.FK_G_Item_Id.Name;
          selectedSaprePartsList.push(partssSelected);
          //this.InventoryCheck(x.FK_G_Item_Id.Id);
          this.discountChange(ab, 0);
        }

        if (x.FK_G_Item_Type_Id.Id === 19) {
          let ab =  new GPackageItemViewModel();
          ab.Quantity = x.Quantity;
          ab.Fk_G_SSItemDefining_Id = new ItemViewModel(x.FK_G_Item_Id.Id, x.FK_G_Item_Id.Name);
          ab.DescountPercentage = 0;
          ab.DescountPercentage = x.Discount;
          ab.SalePrice = 0;
          ab.SalePrice = x.SalePrice;
          ab.Amount = 0;
          ab.Amount = x.TotalAmount;
          ab.DiscountAmount =  0;
          ab.DiscountAmount = x.DiscountAmount;
          ab.DescountAmmount = 0;
          ab.DescountAmmount = x.DiscountedRate;
          this.vmP.Services.push(ab);
          let serviceslect = new GItemDefiningViewModel();
          serviceslect.Id = x.FK_G_Item_Id.Id;
          serviceslect.Name = x.FK_G_Item_Id.Name;
          selectedServicesList.push(serviceslect);
          this.discountChange(ab, 0);
        }
      });
      this.selectedSpareParts = selectedSaprePartsList;
      this.SelectedItemServices = selectedServicesList;
      this.vm.CustomerComplains.forEach(x => {
        let complainSelected = new G_ComplainsSuggestionsViewModel();
        complainSelected.Id = x.FK_G_Complains_Id.Id;
        complainSelected.Name = x.FK_G_Complains_Id.Name;
        selectedComplaintList.push(complainSelected);
      });
      this.selectedComplaint = selectedComplaintList;
      this.vm.Findings.forEach(x => {
        let findingsSelected = new G_ComplainsSuggestionsViewModel();
        findingsSelected.Id = x.FK_G_Complains_Id.Id;
        findingsSelected.Name = x.FK_G_Complains_Id.Name;
        selectedFindingsList.push(findingsSelected);
      });
      this.selectedFindings = selectedFindingsList;
      this.vm.SalesTagsList.forEach(x =>{
        let ab = new G_TagsViewModel();
        ab.Id = x.Id;
        ab.FK_Tag_Id = x.FK_Tag_Id;
        ab.Name = x.Name;
        let selectedTag = new G_TagsViewModel();
        selectedTag.Id = x.Id;
        selectedTag.FK_Tag_Id = x.FK_Tag_Id;
        selectedTag.Name = x.Name;
        selectedTagLists.push(selectedTag);
      });
      this.selectedTagsList = selectedTagLists;
    },(err)=>{
      console.log(err);
      // this._jq.showSpinner();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 3000);
    })
    if (this.vmD == undefined) {
      this.vmD = new DefineAccountviewModel();
      this._uow.accountsService.GetCode().subscribe((res: any) => {
        this.vmD.Acc_Code = res.code;
      }, (err) => console.log(err));
    }
    if (this.vmP == undefined) {
      this.vmC = new G_ComplainsSuggestionsViewModel();
      this.vmP = new GPackagesViewModel();
      this.vmP.Services = [];
      this.vmP.SpareParts = [];
      this.selectedPacakgeTypes = [];
    } else {

      if (this.vmP.Services !== undefined && this.vmP.Services.length > 0) {
        this.vmP.Services.forEach(x => {
          this.selectedServices.push(new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name));
        });
      }
      if (this.vmP.SpareParts !== undefined && this.vmP.SpareParts.length > 0) {
        this.vmP.SpareParts.forEach(x => {
          this.selectedSpareParts.push(new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name));
        });
      }
      if(this.vm.SalesTagsList !== undefined && this.vm.SalesTagsList.length > 0){
        this.vm.SalesTagsList.forEach(x => {
          this.selectedTagsList.push(new ItemViewModel(x.Id, x.Name));
        });
      }
      if (this.vmP.GPackagesModel !== undefined && this.vmP.GPackagesModel.length > 0) {
        this.vmP.GPackagesModel.forEach(x => {
          this.selectedPacakgeTypes.push(new ItemViewModel(x.Fk_G_Pacakage_Id.Id, x.Fk_G_Pacakage_Id.Name));
        });
      }
      if (this.vm.CustomerComplains !== undefined && this.vm.CustomerComplains.length > 0) {
        this.vm.CustomerComplains.forEach(x => {

          this.selectedComplaint.push(new ItemViewModel(x.FK_G_Complains_Id.Id, x.FK_G_Complains_Id.Name));
        });
      }
      if (this.vm.Findings !== undefined && this.vm.Findings.length > 0) {
        this.vm.Findings.forEach(x => {
          this.selectedFindings.push(new ItemViewModel(x.FK_G_Complains_Id.Id, x.FK_G_Complains_Id.Name));
        });
      }
    }
    if (this.modal_title == undefined) {
      this.modal_title = 'Package';
    }


  }

  onChange(e) {
    debugger
    let item = this.itemVm.Fk_G_Customer_Id_Email;
    if(e== true){
          this.vm.FK_Customer_Acc_Email = new ItemViewModel(item.Id , item.Name);
          this.vm.IsEmailRequired = true;
          console.log(this.vm.FK_Customer_Acc_Email);
      }else{
        this.vm.FK_Customer_Acc_Email = null;
        this.vm.IsEmailRequired = false;
      }
}

	onSelect(e) {
    debugger
    this.files.push(...e.addedFiles);
    // this.vm.AttachmentList.push(...e.addedFiles);
    this.vm.file = this.files;
	}

	onRemove(e) {
		console.log(e);
		this.files.splice(this.files.indexOf(e), 1);
	}


  toggle() {
    this.show = !this.show;
    !this.show == this.show;
  }

  getimage()
  {
    this._uow.settingservice.GetByImage().subscribe((baseImage: any) =>{
      console.log(baseImage);
      if (baseImage != undefined &&  baseImage!==null) {
        if (baseImage.Attachment != undefined &&  baseImage.Attachment !==null) {

          this.thumbnail = "https://garagedesks.s3.eu-central-1.amazonaws.com/" + baseImage.Attachment;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }else{
          this.thumbnail = baseImage.ComName;
          this.ComName = baseImage.ComName;
          this.Address = baseImage.Address;
          this.contect = baseImage.contect;
        }
      }

    },(err)=>{
      console.log(err);
      this._jq.showSpinner();
    })
  }

  getPackageTypes() {
    this._uow.packageService.GetList().subscribe((res: any) => {
      this.packageTypes = res;
      this._uow.logger.log(res);

    }, (error) => {
      this._uow.logger.log(error);
      this.packageTypes = [];
    });
  }

  getComplaints() {
    this._uow.complainsAndSuggestionsService.GetList().subscribe((res: any) => {
      this.complaintsType = [];
      this.complaintsType = res;
      this._uow.logger.log(res);

    }, (error) => {
      this._uow.logger.log(error);
      this._jq.showSpinner();
    });
  }

  getFindings() {
    this._uow.complainsAndSuggestionsService.GetList().subscribe((res: any) => {
      this.findingsType = [];
      this.findingsType = res;
      this._uow.logger.log(res);

    }, (error) => {
      this._uow.logger.log(error);
      this._jq.showSpinner();
    });
  }

  getBrands() {
    this._uow.brandService.GetList().subscribe((res: any) => {
      this.brands = res;
    }, (err) => {
      this.brands = [];
      this._uow.logger.log(err);
      this._jq.showSpinner();
    });
  }

  getItemtypes() {
    this._uow.itemTypeService.GetList().subscribe((res: any) => { this.itemTypes = res; }, (error) =>
    {
      this._uow.logger.log(error);
      this._jq.showSpinner();
    });
  }

  getItems() {
    this._uow.itemService.GetList().subscribe((res: any) => {
      this.items = res.DtlList;
      this._uow.logger.log(res.DtlList);

    }, (err) => {
      this._uow.logger.log(err);
      this.items = [];
      this._jq.showSpinner();
    });
  }

  getSparePartsAndServices() {
    //'Spare-Parts'
    this._uow.itemService.GetSSItemDefiningByItemType('Spare-Parts').subscribe((res: any) => {
      // this._uow.logger.log(res);
      this.spareParts = res;
      // this._uow.logger.log(`spare parts ${JSON.stringify(res)}`);

    }, (err) => {
      this._uow.logger.log(err);
      this.spareParts = [];
    });
    // 'Services'
    this._uow.itemService.GetSSItemDefiningByItemType('Services').subscribe((res: any) => {
      this.services = res;
      // this._uow.logger.log(`services ${res}`);
    }, (err) => {
      this._uow.logger.log(err);
      this.services = [];
      this._jq.showSpinner();
    });
  }

  getModelsForBrands(e) {
    var ids = [];
    this.selectedBrands.forEach(b => {
      ids.push(b.Id);
    });
    this._uow.modelService.GetModelsListByBrands(ids).subscribe((res: any) => {
      this.models = res;
      if (this.selectedModels.length > 0) {
        this.selectedModels.forEach(m => {
          let f = this.models.find(x => x.Id == m.Id);
          this._uow.logger.log(m);
          if (f == undefined) {
            this.onModelSelected(m);
          }
        });
      }
      this._uow.logger.log(this.models);
    }, (error) => {
      this._uow.logger.log(error);
      this._jq.showSpinner();
    });
  }

  onModelSelected(e) {
    this._uow.logger.log(e.Id);
    // let model = this.models.find(x => x.Id == e.Id);
    // if (model !== undefined) {
    //   this.vm.GItemModels.push(model);
    // }
  }

  saveOrUpdate() {
    // this._jq.showSpinner(true);
    this.spinner.show();
    this.vm.Inv_Amount = this.vmP.PackageAmount;
    this.onChange(true);
    this.vm.IsEmailRequired == true;
    this._jq.showSpinner(true);
    this._uow.saleService
    .Post(this.vm)
    .toPromise().then((res: any) => {
      console.log(res);
      this.submitted = true;
        // this._jq.showSpinner();
      setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
        }, 5000);
      this.onSubmitted.emit(this.submitted);
        //this.bsmRef.hide();
      Swal.fire(
          'Saved!',
          'Repair Order Created!',
          'success'
        )
      this._router.navigate(['/repair-order-list']);
        // this._router.navigate(['/repair-order-pdf', {id: res.Id}]);
      })
      .catch((error) => {
        Swal.fire(
          'Error!',
          'Repair Order Not Saved!',
          'error'
        )
        this._uow.logger.log(`Promise rejected with ${JSON.stringify(error)}`);
        this.onSubmitted.emit(false);
        // this._jq.showSpinner();
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
        }, 5000);
      });
  }

  navigateClick(){


  }


  compareFn(optionOne, optionTwo): boolean {
    if (optionOne !== undefined && optionTwo !== undefined) {
      return optionOne.Id === optionTwo.Id;
    }
  }



  quantityChange(e, i?) {
    debugger
        //e.DiscountAmount = 0;
    e.Amount = e.Quantity * e.DiscountAmount;
    let saledetail = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id);
    if(saledetail != undefined){
          saledetail.Quantity = e.Quantity;
          saledetail.TotalAmount = e.Amount;
        }
        //  if (e.DescountPercentage > 0) {
        //    e.Amount = (e.Amount - ((e.DescountPercentage / 100) * e.Amount));
        //  }

    this.calculateTotals();
      }

      discountChange(e, i?) {
        debugger
        let discountAMmount = ((e.DescountPercentage * e.SalePrice)) / 100 ;
        e.DescountAmmount =discountAMmount
        e.DiscountAmount = e.SalePrice-e.DescountAmmount;
        e.Amount = e.DiscountAmount * e.Quantity;
        let saledtl = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === e.Fk_G_SSItemDefining_Id.Id);
        if (saledtl != undefined) {
        saledtl.DiscountAmount = e.DescountAmmount;
        saledtl.Discount = e.DescountPercentage;
        saledtl.DiscountedRate = e.DiscountAmount;
        saledtl.TotalAmount =e.Amount;
       }
        // if(this.vmP.DiscountAmount > 0){
        //
        //   this.calculateTotals();
        // }
        // if(this.vmP.DiscountAmount <= 0){
        //   let discountAMmount = ((e.DescountPercentage / 100) * e.Amount);
        //   e.Amount = (e.Amount - discountAMmount);
        //   this.calculateTotals();
        // }
        this.calculateTotals();
      }

      amountChange(e, i?) {

        e.DescountPercentage = ((e.DescountAmmount * 100) / e.SalePrice);
        this.discountChange(e, i);
        //this.calculateTotals();
      }

  // spare parts
  onSelectSparePart(e) {
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    this._uow.logger.log(e);
    ;
    let item = this.spareParts.find(x => x.Id === e.Id);
    if (item !== undefined) {
      let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.DiscountedRate = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      i.Discount = 0;
      i.DiscountAmount = 0;
      this.vm.SaleDetails.push(i);
      let a =  new GPackageItemViewModel();
      a.Id = 0;
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      a.DiscountAmount =  item.SalePrice;
      if (this.itemVm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
      }
      this.vmP.SpareParts.push(a);
      this.discountChange(this.vmP.SpareParts[this.vmP.SpareParts.indexOf(a)]);
    }


    //this.calculateTotals();
  }

  onDeSelectSparePart(e) {

    let i = this.vmP.SpareParts.find(x => x.Fk_G_SSItemDefining_Id.Id === e.Id);
    if (i !== undefined) {
      this.vmP.SpareParts.splice(this.vmP.SpareParts.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    this.calculateTotals();
  }

  //service
  onSelectService(e) {
    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }
    if (this.SelectedItemServices == undefined || this.SelectedItemServices.length == 0) {
      this.SelectedItemServices = [];
    }
    this._uow.logger.log(e);
    let item = this.services.find(x => x.Id === e.Id);
    if (item !== undefined) {
      let i = new SaleDetailViewModel();
      i.Id = 0;
      i.Quantity = 1;
      i.FK_G_Item_Id = new ItemViewModel(item.Id, item.Name);
      i.Item_Code = item.Code;
      i.Item_Name = item.Name;
      i.PurchasePrice = item.PurchasePrice;
      i.SalePrice = item.SalePrice;
      i.TotalAmount = item.SalePrice;
      i.Rate = item.SalePrice;
      i.DiscountedRate = item.SalePrice;
      i.Item_Type = item.FK_G_ItemType_Id.Name;
      i.VatAmount = 0;
      i.VAT_Value = 0;
      i.Discount = 0;
      i.DiscountAmount = 0;
      this.vm.SaleDetails.push(i);
      let a =  new GPackageItemViewModel();
      a.Id = 0;
      a.Quantity = 1;
      a.Fk_G_SSItemDefining_Id = new ItemViewModel(item.Id, item.Name);
      a.DescountPercentage = 0;
      a.SalePrice = item.SalePrice;
      a.Amount = 0;
      a.Amount = item.SalePrice;
      a.DiscountAmount =  item.SalePrice;
      if (this.itemVm.Fk_G_Customer_Discount > 0) {
        a.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
      }
      this.vmP.Services.push(a);
      this.discountChange(this.vmP.Services[this.vmP.Services.indexOf(a)]);
      // let serviceslect = new GItemDefiningViewModel();
      // serviceslect.Id = item.Id;
      // serviceslect.Name = item.Name;
      //this.SelectedItemServices.push(item);
    }


    //this.calculateTotals();
  }

  onDeSelectService(e) {

    let i = this.vmP.Services.find(x => x.Fk_G_SSItemDefining_Id.Id === e.Id);
    if (i !== undefined) {
      this.vmP.Services.splice(this.vmP.Services.indexOf(i), 1);
    }
    let d = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === e.Id);
    if (d !== undefined) {
      this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d), 1);
    }
    this.calculateTotals();
  }

  getTags() {
    this._uow.tagsService.GetList().subscribe((res: any) => { this.tags = res; }, (err) => {
      console.log(err);
      this._jq.showSpinner();
    });
  }

  // Package
  onSelectPackage(e,i?) {

    if (this.vm.SaleDetails == undefined || this.vm.SaleDetails.length == 0) {
      this.vm.SaleDetails = [];
    }

   // let iii = document.getElementById(this.spareParts[0].Id);
    this._uow.logger.log(e);
    let item = this.packageTypes.find(x => x.Id === e.Id);
    if (item !== undefined) {
      let selectedServicesList : any = [];
      let selectedSaprePartsList : any = [];
      if (this.vm.SaleDetails != undefined && this.vm.SaleDetails.length > 0) {
        this.vm.SaleDetails.forEach(x=> {

          if (x.Item_Type === 'Services') {
                let serviceslect = new GItemDefiningViewModel();
                serviceslect.Id =   x.FK_G_Item_Id.Id;
                serviceslect.Name = x.FK_G_Item_Id.Name;
                selectedServicesList.push(serviceslect);
              }
          if (x.Item_Type === 'Spare-Parts') {

                    let partssSelected = new GItemDefiningViewModel();
                    partssSelected.Id = x.FK_G_Item_Id.Id;
                    partssSelected.Name = x.FK_G_Item_Id.Name;
                    selectedSaprePartsList.push(partssSelected);
                  }
        })
        //this.selectedSpareParts = selectedSaprePartsList;
        //this.SelectedItemServices = selectedServicesList;
      }

      item.GPackagesItem.forEach(x => {

        let alreadyExisting = this.vm.SaleDetails.find(z=> z.FK_G_Item_Id.Id === x.Fk_G_SSItemDefining_Id.Id);
        if (alreadyExisting == undefined || alreadyExisting == null) {
          if (x.FK_G_ItemType_Id.Id === 18) {
            let selectedparts = this.spareParts.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);
            //if (selectedparts !== undefined) {
            let i = new SaleDetailViewModel();
            i.Id = 0;
            i.Quantity = x.Quantity;
            i.FK_G_Item_Id = new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name);
            i.Item_Code = x.Code;
            i.Item_Name = x.Fk_G_SSItemDefining_Id.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.TotalAmount = x.Amount;
            i.Rate = x.SalePrice;
            i.DiscountedRate = x.SalePrice;
            i.Item_Type = x.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            i.Discount = 0;
            i.DiscountAmount = 0;
            this.vm.SaleDetails.push(i);
            let ab =  new GPackageItemViewModel();
            ab.Id = 0;
            ab.Quantity = x.Quantity;
            ab.DescountPercentage = 0;
            ab.DescountPercentage = x.DescountPercentage;
            ab.Fk_G_SSItemDefining_Id = new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name);
            ab.SalePrice = 0;
            ab.SalePrice = x.SalePrice;
            ab.Amount = 0;
            ab.Amount = x.Amount;
            ab.DiscountAmount =  0;
            if(this.itemVm.Fk_G_Customer_Discount > 0){
                ab.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
              }
            this.vmP.SpareParts.push(ab);
            this.discountChange(ab, i);

            let partssSelected = new GItemDefiningViewModel();
            partssSelected.Id = x.Fk_G_SSItemDefining_Id.Id;
            partssSelected.Name = x.Fk_G_SSItemDefining_Id.Name;
            selectedSaprePartsList.push(partssSelected);
            //}

          }

          if (x.FK_G_ItemType_Id.Id === 19) {

            let selectedservices = this.services.find(y => y.Id === x.Fk_G_SSItemDefining_Id.Id);
            //if (selectedservices !== undefined) {
            let i = new SaleDetailViewModel();
            i.Id = 0;
            i.Quantity = x.Quantity;
            i.FK_G_Item_Id = new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name);
            i.Item_Code = x.Code;
            i.Item_Name = x.Fk_G_SSItemDefining_Id.Name;
            i.PurchasePrice = x.SalePrice;
            i.SalePrice = x.SalePrice;
            i.TotalAmount = x.Amount;
            i.Rate = x.SalePrice;
            i.DiscountedRate = x.SalePrice;
            i.Item_Type = x.FK_G_ItemType_Id.Name;
            i.VatAmount = 0;
            i.VAT_Value = 0;
            i.Discount = 0;
            i.DiscountAmount = 0;
            this.vm.SaleDetails.push(i);
            let ab =  new GPackageItemViewModel();
            ab.Id = 0;
            ab.Quantity = x.Quantity;
            ab.DescountPercentage = 0;
            ab.DescountPercentage = x.DescountPercentage;
            ab.Fk_G_SSItemDefining_Id = new ItemViewModel(x.Fk_G_SSItemDefining_Id.Id, x.Fk_G_SSItemDefining_Id.Name);
            ab.SalePrice = 0;
            ab.SalePrice = x.SalePrice;
            ab.Amount = 0;
            ab.Amount = x.Amount;
            ab.DiscountAmount =  0;
            if(this.itemVm.Fk_G_Customer_Discount > 0){
                ab.DescountPercentage = this.itemVm.Fk_G_Customer_Discount;
              }
            this.vmP.Services.push(ab);
              //this.selectedServices.push(a);

            let serviceslect = new GItemDefiningViewModel();
            serviceslect.Id = x.Fk_G_SSItemDefining_Id.Id;
            serviceslect.Name = x.Fk_G_SSItemDefining_Id.Name;
            selectedServicesList.push(serviceslect);
            this.discountChange(ab, i);
            //}

          }
        }

      });
      this.selectedSpareParts = selectedSaprePartsList;
      this.SelectedItemServices = selectedServicesList;
      }
    console.log(e);
  }

  onDeSelectPackage(e) {

    this._uow.logger.log(e);
    let item = this.packageTypes.find(x => x.Id === e.Id);
    if (item !== undefined) {
        this.selectedSpareParts = [];
        this.SelectedItemServices = [];
        let selectedServicesList : any = [];
        let selectedSaprePartsList : any = [];


        item.GPackagesItem.forEach(y => {

          let c = this.vmP.SpareParts.find(x => x.Fk_G_SSItemDefining_Id.Id === y.Fk_G_SSItemDefining_Id.Id);

          if (c !== undefined) {
            this.vmP.SpareParts.splice(this.vmP.SpareParts.indexOf(c),1);
          }

          let a = this.vmP.Services.find(x => x.Fk_G_SSItemDefining_Id.Id === y.Fk_G_SSItemDefining_Id.Id);

          if (a !== undefined) {
          this.vmP.Services.splice(this.vmP.Services.indexOf(a),1);

        }

          let d = this.vm.SaleDetails.find(x => x.FK_G_Item_Id.Id === y.Fk_G_SSItemDefining_Id.Id);
          if (d !== undefined) {
          this.vm.SaleDetails.splice(this.vm.SaleDetails.indexOf(d),1);
        }


          this.calculateTotals();
        });
        if (this.vm.SaleDetails != undefined && this.vm.SaleDetails.length > 0) {
          this.vm.SaleDetails.forEach(x=> {

            if (x.Item_Type === 'Services') {
                  let serviceslect = new GItemDefiningViewModel();
                  serviceslect.Id =   x.FK_G_Item_Id.Id;
                  serviceslect.Name = x.FK_G_Item_Id.Name;
                  selectedServicesList.push(serviceslect);
                }
            if (x.Item_Type === 'Spare-Parts') {

                      let partssSelected = new GItemDefiningViewModel();
                      partssSelected.Id = x.FK_G_Item_Id.Id;
                      partssSelected.Name = x.FK_G_Item_Id.Name;
                      selectedSaprePartsList.push(partssSelected);
                    }
          })
          this.selectedSpareParts = selectedSaprePartsList;
          this.SelectedItemServices = selectedServicesList;
        }

      }
  }

   // Tags
   onSelectTags(e) {
    if (this.vm.SalesTagsList == undefined || this.vm.SalesTagsList.length == 0) {
      this.vm.SalesTagsList = [];
    }

    this._uow.logger.log(e);
    let item = this.tags.find(x => x.Id === e.Id);
    if (item !== undefined) {

      let i = new G_TagsViewModel();
      i.Name = item.Name;
      i.FK_Tag_Id =  item.Id;
      this.vm.SalesTagsList.push(i);
      // let a = new G_SaleMasterViewModel();
      // a.Id = item.Id;
      // a.Name = item.Name;
      // this.vm.SalesTags.push(i);

      }

  }

  onDeSelectTags(e) {
    let i = this.vm.SalesTagsList.find(x => x.FK_Tag_Id === e.Id);
    if (i !== undefined) {
      this.vm.SalesTagsList.splice(this.vm.SalesTagsList.indexOf(i), 1);
    }

  }

  // Complaints
  onSelectComplaints(e) {
 if (this.vm.CustomerComplains == undefined || this.vm.CustomerComplains.length == 0) {
      this.vm.CustomerComplains = [];
    }

 this._uow.logger.log(e);
 let item = this.complaintsType.find(x => x.Id === e.Id);
 if (item !== undefined) {
      let i = new G_ComplainsSuggestionsViewModel();
      i.Name = item.Name;
      i.FK_G_Complains_Id = new ItemViewModel(item.Id, item.Name);
      this.vm.CustomerComplains.push(i);
    }
  }

  onDeSelectComplaints(e) {
    let i = this.vm.CustomerComplains.find(x => x.FK_G_Complains_Id.Id === e.Id);
    if (i !== undefined) {
      this.vm.CustomerComplains.splice(this.vm.CustomerComplains.indexOf(i), 1);
     }

  }

  // Findings
  onSelectFindings(e) {
    if (this.vm.Findings == undefined || this.vm.Findings.length == 0) {
      this.vm.Findings = [];
    }

    this._uow.logger.log(e);
    let item = this.findingsType.find(x => x.Id === e.Id);
    if (item !== undefined) {
      let i = new G_ComplainsSuggestionsViewModel();
      i.Name = item.Name;
      i.FK_G_Complains_Id = new ItemViewModel(item.Id, item.Name);
      this.vm.Findings.push(i);
    }
  }

  onDeSelectFindings(e) {
    let i = this.vm.Findings.find(x => x.FK_G_Complains_Id.Id === e.Id);
    if (i !== undefined) {
      this.vm.Findings.splice(this.vm.Findings.indexOf(i), 1);
     }
  }



  calculateTotals() {
    this.vmP.DiscountAmount = 0;
    this.vmP.ServiceAmount = 0;
    this.vmP.Services.forEach(x => {
      this.vmP.ServiceAmount += x.Amount;
      if (x.DescountAmmount > 0) {
        this.vmP.DiscountAmount += (x.DescountAmmount * x.Quantity);
      }
    });

    this.vmP.PartsAmount = 0;
    this.vmP.SpareParts.forEach(x => {
      this.vmP.PartsAmount += x.Amount;
      if (x.DescountAmmount > 0) {
        this.vmP.DiscountAmount += (x.DescountAmmount * x.Quantity);
      }
    });

    // this.vmP.SpareParts.forEach(x => {
    //  this.vmP.PartsAmount += x.Amount;
    //   if (x.DescountAmmount > 0) {
    //     //this.vmP.DiscountAmount += (x.Amount - x.DescountAmmount);
    //     this.vmP.DiscountAmount = x.Amount-x.DescountAmmount;
    //     this.vmP.DiscountAmount = x.DescountAmmount;
    //   }
    // });

    // this.vmP.Services.forEach(x => {
    //   //this.vmP.ServiceAmount += x.Amount;
    //   if (x.DescountAmmount > 0) {
    //     this.vmP.DiscountAmount = x.Amount-x.DescountAmmount;
    //   }
    // });

    this.vmP.PackageAmount = this.vmP.PartsAmount + this.vmP.ServiceAmount;
  }

  onBrandSelected(e) {
    this.getModelsForBrands(e);
  }

  onBrandDeSelected(e) {
    this.getModelsForBrands(e);
  }

  openModal(template: TemplateRef<any>, lvl: number = 1) {
    if (lvl == 1) {
      this.modalRef = this._uow.modalServiceEx.show(template);
    }
    // if (lvl == 2) {
    //   this.modalRefLv2 = this._modalService.show(template);
    // }
  }

  openModalLg(template: TemplateRef<any>, itemtype?) {
    this.ssItemVm = new GItemDefiningViewModel();
    if (itemtype == 1) {
      let i = this.itemTypes.find(x => x.Name == 'Spare-Parts');
      console.log(i)
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    if (itemtype == 2) {
      let i = this.itemTypes.find(x => x.Name == 'Services');
      this.ssItemVm.FK_G_ItemType_Id = new ItemViewModel(i.Id, i.Name);
    }
    this.openModal(template);
    this.modalRef.setClass('modal-lg');
  }

  openPreview(content) {
    this.modalRef = this._uow.modalServiceEx.show(content);
    this.modalRef.setClass('modal-lg');
    this.isPreview = true;
  }

  // extrnal components
  onItemSubmitted(e, mf) {
    this._uow.logger.log(e);
    if (e === true) {
      if (mf == this.cbrands) {
        this.getBrands();
      }
      if (mf == this.citems) {
        this.getItems();
      }
      if (mf == this.cpackageTypes) {
        this.getPackageTypes();
      }
      if (mf == this.ccomplaintsType) {
        this.getComplaints();
      }
      if (mf == this.cmodels) {
        this.getModelsForBrands(e);
      }
    }
  }

    getRegNo() {
      this._jq.showSpinner(true);
      this._uow.vehicleService.GetList().subscribe((res: any) => {
        console.log(res);
        this._jq.showSpinner();
        this.vm.Fk_G_Vechile_Id = res.code;
      }, (error) => {
        console.log(error);
        this._jq.showSpinner();
      });
    }

    //'Spare-Parts'
    getSpareParts() {
      this._jq.showSpinner(true);
      this._uow.itemService.GetSSItemDefiningByItemType('Spare-Parts').subscribe((res: any) => {
        this.spareParts = res;
        console.log(res);
        this._jq.showSpinner();
      }, (err) => {
        this._uow.logger.log(err);
        this.spareParts = [];
        this._jq.showSpinner();
      });
    }

    getRequisitions() {
      this._uow.vehicleService.GetList().subscribe((res: any) => {
        console.log(res.DtlList);
        this.vehicleDetail = res.RegistraionNumber;
        this._jq.showSpinner();
      }, (error) => {
        console.log(error);
        this._jq.showSpinner();
      });
    }

    getVehicleDetail() {
      //'Spare-Parts'
      this._jq.showSpinner(true);
      this._uow.vehicleService.GetById('Vehicle-Detail').subscribe((res: any) => {
        this.vehicleDetail = res;
        console.log(res);
        this._jq.showSpinner();
      }, (err) => {
        this._uow.logger.log(err);
        this.vehicleDetail = [];
        this._jq.showSpinner();
      });
    }

    openModal1(template: TemplateRef<any>, lvl: number = 1) {
      if (lvl == 1) {
        this.modalRef = this._uow.modalServiceEx.show(template);
      }
      //  if (lvl == 2) {
      //    this.modalRefLv2 = this._modalService.show(template);
      //  }
    }

    getSortIndexList(sIndex) {
      this._jq.showSpinner(true);
      this._uow.vehicleService.GetPagedResult(0, 50, sIndex).subscribe((res: any) => {
        this.vmListItems = res.DtlList;
        console.log(res);
        this._jq.showSpinner();
      }, (err) => {
        console.log(err);
        this._jq.showSpinner();
      });
    }

    openSaveOrUpdateModel(template: TemplateRef<any>, model?) {
      this.openModal(template, 1);
      if (model !== undefined) {
        this.itemVm = model;
      } else {
        this.vm = new G_SaleMasterViewModel();
      }
    }

    openDeleteModel(template: TemplateRef<any>, model) {
      this.openModal(template, 1);
      if (model !== undefined) {
        this.itemVm = model;
      }
    }

    conformDelete() {
      this._jq.showSpinner(true);
      this._uow.saleService.Delete(this.vm).subscribe((res: any) => {
        this.getList();
        this._jq.showSpinner();
        //this.modalRefLv1.hide();
      }, (err) => {
        this._jq.showSpinner();
      });
    }

    getList() {
      this._jq.showSpinner(true);
      this._uow.vehicleService.GetList().subscribe((res: any) => {
        this.vmListItems = res.DtlList;
        console.log(res);
        this._jq.showSpinner();
      }, (err) => {
        console.log(err);
        this._jq.showSpinner();
      });
    }

    // input changes
    quantityOrPriceChanged(m) {
      m.TotalAmount = m.Quantity * m.PurchasePrice;
      this.updateTotals();
    }

    public TotalPayable = 0;
    public TotalVat = 0;
    public TotalPayableAfterVat = 0;
    updateTotals() {
      this.TotalPayableAfterVat = 0;
      this.TotalVat = 0;
      this.TotalPayable = 0;
      if (this.itemVm.SaleDetails !== undefined && this.itemVm.SaleDetails.length > 0) {
        this.itemVm.SaleDetails.forEach(x => {
          this.TotalPayable += x.TotalAmount;
          this.TotalVat += x.VatAmount;
          this.TotalPayableAfterVat += (x.TotalAmount + x.VatAmount);
        });
      }
    }

    //Image-Upload-Function
}
