import { Component, OnInit, TemplateRef } from '@angular/core';
import { ItemStockReportViewModel } from 'src/app/viewmodels/g-item/ItemStockReportViewModel';
import { JqHelper } from 'src/app/common/jq-helper';
import { UnitOfWorkService } from 'src/app/services/api/uow/unit-of-work.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { StockReportTotalViewModel } from 'src/app/viewmodels/g-item/StockReportTotalViewModel';
import { ExportExcel } from 'src/app/viewmodels/ExportExcel';
import Swal from 'sweetalert2';
import { LocalStorageService } from 'src/app/services/common/local-storage.service';

@Component({
  selector: 'app-itemstockreport',
  templateUrl: './itemstockreport.component.html',
  styleUrls: ['./itemstockreport.component.scss']
})
export class ItemstockreportComponent implements OnInit {
  modalRefLv1: BsModalRef;
  modalRefLv2: BsModalRef;
  public itemVm: ItemStockReportViewModel = new ItemStockReportViewModel();
  public stockTotal: StockReportTotalViewModel = new StockReportTotalViewModel();
  public vmListItems: any = [];
  p: number = 1;
  public component_Title = 'Item Stock Report';
  public exportFormat: string = 'csv';
  startDate: any = null;
  endDate : any = null;
  public client: any;
  constructor(
    private _jq: JqHelper,
    private _uow: UnitOfWorkService,
    private _modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private _localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.client = this._localStorageService.get("com");
    this.itemVm = new ItemStockReportViewModel();
    // this._jq.showSpinner(true);
    this.spinner.show();
    this._uow.itemService.GetItemReport().subscribe((res: any) => {
      this.vmListItems = res.DtlList;
      this.stockTotal = res;
      console.log(res);
      console.log(this.itemVm);
      // this._jq.showSpinner();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      // this._jq.showSpinner();
      this.spinner.hide();
    });
  }

  public searchTerm = '';
  getBySearchTerm(e) {
    if (this.searchTerm !== ' ') {
      // this._jq.showSpinner(true);
      this.spinner.show();
      this._uow.itemService.GetPagedBySearch(this.searchTerm).subscribe((res: any) => {
        this.vmListItems = res.DtlList;
        // this._jq.showSpinner();
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        // this._jq.showSpinner();
        this.spinner.hide();
      });
    }
  }
  openModal(template: TemplateRef<any>) {
    this.modalRefLv1 = this._uow.modalServiceEx.show(template);
    this.modalRefLv1.setClass('modal-lg');
  }
  openExportModel(template: TemplateRef<any>) {
    this.openModal(template);
  }

  exportToExcel(){
    debugger;
    this.spinner.show();
        var obj = new ExportExcel();
        obj={
          StartDate: this.startDate,
          EndDate: this.endDate,
          Format: this.exportFormat
        }
    this._uow.itemService.ExportStockReport(obj).subscribe((res:any)=>{
    debugger;
    
    const blob = new Blob([res], { type: res.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `ItemStockReportData_${new Date().toISOString().split('T')[0]}.${this.exportFormat.toLowerCase()}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
    
            this.spinner.hide();
            this.modalRefLv1.hide();
    },
    (error)=>{
      this._uow.logger.log(error);
      this.spinner.hide();
      Swal.fire(
        'Failure!',
        'Network Issue!',
        'error'
      )
    });
      }

}
