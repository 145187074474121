import { ItemViewModel } from '../ItemViewModel';

export class GQuotationSaveModel {

    public Id: number;
    public FK_Client_Id: ItemViewModel;
    public FK_Vehicle: ItemViewModel;
    public FK_Customer: ItemViewModel;
    public VehicleNumber: string;
    public Odometer: string;
    public DriverName: string;
    public DriverPhone: string;
    public QuoteDate: Date;
    public Subject: string;
    public Note: string;
    public QuoteNumber: string;
    public CustomerCode: string;
    public Vat?: number;
    public FK_Werahouse: ItemViewModel;
    public GQuotationDetail: GQuotationDetail[];
    public GQuotationTags: GQuotationTags[];
    public GQuotationAttachments: GQuotationAttachments[];
    public ServiceTotal?: number;
    public PartsTotal?: number;
    public TotalDiscount?: number;
    public TotalVat?: number;
    public Total?: number;
    public Attachment: string;
    public file: any = [];

}

export class GQuotationDetail{
    public Id: number;
    public GQuotationId: number;
    public ItemName: string;
    public Type: string;
    public Quantity: number;
    public Rate: number;
    public VatAmount: number;
    public DiscountRate: number;
    public DiscountPercantage: number;
    public DiscountAmount: number;
    public TotalAmount: number;
    public TotalAmountWithVat: number;
}

export class GQuotationTags{
    public Id: number;
    public GQuotationId: number;
    public Tag: string;
}

export class GQuotationAttachments{
    public Id: number;
    public GQuotationId: number;
    public Attachment: string;
}