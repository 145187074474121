import { ItemViewModel } from '../ItemViewModel';
import { G_TagsViewModel } from "./G_TagsViewModel";
import { G_ComplainsSuggestionsViewModel } from '../g-settings/G_ComplainsSuggestionsViewModel';
import { SaleDetailViewModel } from './SaleDetailViewModel';
import { GPackagesViewModel } from '../g-packages/GPackagesViewModel';

export class G_SaleMasterViewModel {


  public Id: number ;
  public RefId: string ;
  public Inv: number;
  public VatValue: number;
  public Fk_G_Customer_Discount:number;
  public TransactionDate: Date ;
  public Validity: number;
  public Fk_G_Vechile_Id: ItemViewModel;
  public Fk_G_Customer_Id: ItemViewModel;
  public FK_Customer_Acc: ItemViewModel;
  public FK_Cradit_Acc: ItemViewModel;
  public FK_Customer_Acc_Email: ItemViewModel;
  public Fk_G_StatusType_Id: ItemViewModel;
  public Fk_G_Tags_Id : ItemViewModel;
  public Fk_G_SPSType_Id : ItemViewModel;
  public Fk_G_WareHouse_Id: ItemViewModel;
  public FK_Brand : ItemViewModel;
  public FK_Model : ItemViewModel;
  public Remarks: string;
  public PaymentType: number;
  public Staus: string;
  public Inv_Amount: number;
  public PaidAmount: number;
  public IsPaymentDone: boolean;
  public IsCredit: boolean;
  public IsCounterSale: boolean;
  public Odometer: number ;
  public Attachment: string;
  public file: any = [];
  public DriverName: string;
  public RegistraionNumber: string;
  public DriverPhone: string;
  public FK_ClientId: ItemViewModel;
  public SaleDetails: SaleDetailViewModel[] ;
  public CustomerComplains: G_ComplainsSuggestionsViewModel[];
  // public SalesTagsList: Array<G_TagsViewModel>;
  public SalesTagsList: G_TagsViewModel[];
  public Findings: G_ComplainsSuggestionsViewModel[];
  public AttachmentList: string;
  public InvoicesList: string;
  public CreatedBy: string ;
  public CreatedDate: Date;
  public SalesTags:G_TagsViewModel[];
  public PackageDetail: GPackagesViewModel[];
  public SpareParts: SaleDetailViewModel[];
  public Services: SaleDetailViewModel[];
  public IsEmailRequired:boolean=true;
  public IsQRGenerate:boolean=false;
  public PartsAmount: number;
  public ServiceAmount: number;
  public TotalExpense: number;
  public AllowedExpense: number;
}
